.utilityhealthcare {
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: scroll;
  background: linear-gradient(#191a40, #090b22);
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
:root {
  --WIDTH_utilityhealthcare-backround: 95%;
}
.utilityhealthcare-backround {
  height: 90%;
  width: var(--WIDTH_utilityhealthcare-backround);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-left: 60px;
}
.backbutton1 {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 12px;
  margin-left: 10px;
}
.healthcare-left {
  /* display: contents; */
  position: relative;
  /* margin-left: 20rem; */
  /* padding: 0.5rem; */
  width: calc(var(--WIDTH_utilityhealthcare-backround) / 4);

  color: #ededed;
  font-family: "Orbitron";
  /* width: 40%; */

  height: 100%;

  /* background: linear-gradient(rgb(0, 255, 200),rgb(251, 0, 255)); */
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 10px;
  padding: 1.5px;
}
.healthcare-left:hover {
  border: 3px solid #33afeb;
}

.health_container {
  /* display: contents; */
  /* background:#16173B; */
  border-radius: 10px;
  height: 100%;
  width: 25rem;
}
.lastcheckup-content {
  /* line-height:2rem ; */
  /* display: inline-block; */
}
.lastcheckup-content ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}
.lastcheckup-content {
}

.lastcheckup-button {
  border: none;
  background: none;
  height: 62%;
  /* margin-left: 8rem; */
  color: white;
  /* font-size: 1.5rem; */
}

.Health-content {
  /* line-height: 2rem; */
  /* padding: 0px; */
}
.Health-content ul {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  letter-spacing: 1px;
  /* margin-left: 14rem; */
  /* margin-top: -3.8rem; */
  /* line-height: 2rem; */
}
.Ctx > ul > li:last-child {
  /* background: #090B22;n */
  padding: 8px 13px;
  /* border-radius:5px */
  border-radius: 2.156px;
  background: rgba(54, 196, 209, 0.2);
}

.illness-button {
  border: none;
  font-size: 1.5rem;
  background: none;
  /* height: 62%; */
  /* margin-left: 6.5rem; */
  color: white;
  /* font-size: 1.5rem; */
}

.medicines-content1 {
  /* line-height: 2rem; */
}
.medicines-button {
  border: none;
  background: none;
  height: 62%;
  /* margin-left: 10rem; */
  color: white;
  /* font-size: 1.5rem; */
}
.health-info {
  width: 100%;
  height: 40px;
  /* margin-top: 5rem; */
  font-family: "Orbitron";
  color: aliceblue;
  font-size: 18px;
  align-items: center;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  cursor: pointer;
  /* padding-left:1.5rem ; */
  background: linear-gradient(
    90deg,
    rgba(0, 215, 251, 0.29) 0%,
    rgba(217, 217, 217, 0) 100%
  );
}

.health-info:hover {
  border: 2px solid #33afeb;
}
.health-info1:hover {
  border: 2px solid #33afeb;
}
.health-info2:hover {
  border: 2px solid #33afeb;
}

.lineChart_container {
  width: 113%;
  height: 10%;
  z-index: 4;
  position: relative;
  left: 15%;
  /* background: red; */
  /* position: relative; */
  /* background: rgba(0, 8, 255, 0.4);


    /* width: 100%;
    height: 10%;
    z-index: 4;
    position: relative;
    left:15%; */
  /* background: red; */
  /* position: relative; */
  /* background: rgba(0, 8, 255, 0.4); */
}

.chart {
  width: 85%;
  height: 10%;
  /* position: absolute; */
  /* margin-left: 36rem; */
  /* padding: 0.5rem; */
  color: #ededed;
  z-index: 4;
  font-family: "Orbitron";
  /* width: 522px;
        height: 290px; */
  /* width: 34vw; */
  /* height: 37.4vh; */
  background: rgba(22, 23, 59, 0.4);
  box-shadow: 0px 4.04943px 8.09887px rgba(10, 10, 31, 0.8);
  border-radius: 12px;
}
.chart:hover {
  /* border: 3px solid #33AFEB;; */
}

.appointment {
  z-index: 4;
  width: 90%;
  height: 200px;
  /* position: absolute; */
  /* margin-left: 36rem; */
  /* padding: 1rem; */
  color: #ededed;
  font-family: "Orbitron";
  position: relative;
  left: 6%;
  right: 3rem;
  /* top: 440px; */

  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: black; */
  padding: 20px;

  background: linear-gradient(
    85.96deg,
    rgba(107, 26, 121, 0.7) -0.06%,
    rgba(51, 53, 162, 0.7) 100.04%
  );
  box-shadow: 0px 4.05345px 8.10689px rgba(10, 10, 31, 0.8);
}
.appointment:hover {
  /* border: 3px solid #33AFEB; */
}
.left_part_appointment {
  width: 50%;
  font-size: medium;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.left_part_appointment h2 {
  background: linear-gradient(40deg, #00ffb2 0%, #ff00f5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.month {
  width: 100%;
  /* background: red; */
  font-family: "Orbitron";
  /* margin-top: 1rem; */
}
.week {
  /* display: inline-block; */
  /* position: absolute; */
  left: 576px;
  top: 38px;
  background-color: #16173b;
  color: #00d7fb;
  border: none;
  /* display: flex; */
}

.options {
  color: #ededed;
  border: none;
  font-size: 13px;
  font-family: "Orbitron";
  margin: 2px;
  border: none;
  /* background: transparent; */
  background-color: #16173b98;
  margin-top: 15px;
}

.date {
  height: 100%;
  width: 100%;
  /* padding-left: -1rem; */
  text-align: center;
  justify-content: center;
}
.date-button {
  /* box-sizing: border-box; */
  /* margin-right: 0.1rem; */
  /* margin-left: 0rem; */
  /* width: 35px;
height: 65px; */
  /* padding: 0.2rem; */
  font-size: 1.3rem;
  /* padding:; */
  color: #ededed;
  font-family: "Orbitron";
  border: none;
  /* background: #16173B; */
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 31.5px;
  width: 100%;
  height: 100%;
}
.activeSlide .date-button {
  background-color: #00d7fb;
}
.slide {
  transform: scale(0.9);
  transition: transform 300ms;
  /* padding: 30px 0; */
  gap: 2rem;
  width: 500px;
  display: flex;
  /* background: red; */
  height: 100px;
}
.activeSlide {
  transform: scale(1.1);
  /* height: 80%; */
  border: 2px solid transparent;
}
.next {
  /* position: absolute; */
  padding: 10px;
  top: 25%;
  z-index: 1;
  right: -5px;
  /* font-size: 0.6rem; */
  color: white;
  cursor: pointer;
  user-select: none;
  background: none;
}
.next:hover {
  color: #47b2de;
}
.prev {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: -125px;
  /* font-size: 0.6rem; */
  background: none;
  cursor: pointer;
  color: white;
  user-select: none;
}
.prev:hover {
  color: #47b2de;
}
.popup {
  /* position: absolute; */
  width: 35%;
  height: 100%;
  /* width: 152px;
height: 130px; */
  /* margin-top: -8.3rem; */
  /* margin-left: 27.9rem; */
  font-family: "Orbitron";
  font-size: 6px;
  /* border: 1.5px solid white; */
  text-align: center;
  background: linear-gradient(
    157.53deg,
    rgba(255, 255, 255, 0.27) 2.52%,
    rgba(255, 255, 255, 0.24) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16.2138px;
  z-index: 4;
  position: relative;
  padding: 10px;
  /* background: linear-gradient(white); */
}
.popup:hover {
  border: 2px solid #33afeb;
}
.popup-content > h1 {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 2px;
  margin-top: 10px;
}
.popupabout {
  margin: 2px;
  font-size: 0.8rem;
}
.popup-button {
  /* position: absolute; */
  width: 115px;
  height: 30px;
  /* left: 20px; */
  /* top: 108px; */
  border: none;
  border-radius: 8px;

  background: linear-gradient(91.75deg, #52e0ff 0.31%, #ff55cf 107.12%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8.10689px;
  margin-top: 10px;
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translate(-50%);
}
.popup-button:hover {
  border: 2px solid #33afeb;
}
.xxx {
  /* width: 30%; */
  width: calc(var(--WIDTH_utilityhealthcare-backround) / 2);
  /* background-color: #00D7FB; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  /* position:relative; */
}
.helth {
  /* width: 30%; */
  width: calc(var(--WIDTH_utilityhealthcare-backround) / 3 + 2);
  /* position:relative; */
}

.health-display {
  position: relative;
  width: 100%;
  margin-top: 0px;
  top: -3rem;
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  left: 5rem;
  /* align-items: center; */
  /* gap: 10px;

    /* align-items: center; */
  /* gap: 10px; */
}
.inner_health_display {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}

.health-display1 {
  /* position: absolute; */
  /* margin-left: 18.5rem; */
  /* padding: 1rem; */
  padding: 1rem;
  /* margin: 5px;     */
  color: #ededed;
  font-family: "Orbitron";
  width: 15rem;
  height: 8rem;

  /* left: 259px; */
  /* top: 32px; */
  /* background: linear-gradient(18deg,rgb(5, 52, 113),rgb(92, 0, 128)); */
  /* backdrop-filter: blur(83.72px); */
  -webkit-backdrop-filter: blur(83.72px);
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  /* border-radius: 8px; */

  border-radius: 8.623px;
  background: linear-gradient(
    134deg,
    rgba(0, 240, 255, 0.4) 0%,
    rgba(51, 0, 255, 0.4) 100%
  );
  backdrop-filter: blur(90.23748779296875px);
  /* display: flex; */
}
.health-display1:hover {
  border: 2px solid #33afeb;
}
.Reading_healthDisplay {
  height: 80%;
  display: flex;
  flex-direction: column;
  /* align-items: end; */
  justify-content: end;
  letter-spacing: 2px;
}
.Reading_healthDisplay > h3 {
  font-size: 1rem;
}
.Reading_healthDisplay > .bp,
.hr,
.sc,
.wtr {
  font-size: 1.8rem;
}
.header_HealthDisplay1 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.health-display1 > span {
  /* text-align: right; */
}

.health-display2 {
  /* position: absolute; */
  margin-left: 42rem;
  padding: 1rem;
  color: #ededed;
  font-family: "Orbitron";
  width: 12vw;
  height: 15vh;
  left: 184px;
  top: 32px;
  background: rgba(217, 217, 217, 0.121);
  backdrop-filter: blur(83.72px);
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
}
.health-display2:hover {
  border: 2px solid #33afeb;
}
.health-display3 {
  /* position: absolute; */
  margin-left: 18.5rem;
  padding: 1rem;
  color: #ededed;
  font-family: "Orbitron";
  width: 12vw;
  height: 15vh;
  left: 259px;
  top: 235px;
  background: rgba(217, 217, 217, 0.121);
  backdrop-filter: blur(83.72px);
  border: 2px solid #33afeb;
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
}
.health-display3:hover {
  border: 2px solid #33afeb;
}
.health-display4 {
  /* position: absolute; */
  /* margin-left: 42rem; */
  /* padding: 1rem; */
  color: #ededed;
  font-family: "Orbitron";
  width: 12vw;
  height: 15vh;
  left: 184px;
  top: 235px;
  background: rgba(217, 217, 217, 0.121);
  backdrop-filter: blur(83.72px);
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
}
.health-display4:hover {
  border: 2px solid #33afeb;
}
.bloodpressure {
  word-spacing: 5px;
  display: inline-block;
}

.icon1 {
  /* margin-left: -0.8rem; */
  height: 14px;
}
.icon7 {
  /* margin-left: -0.9rem; */
  height: 9px;
}
.icon6 {
  /* margin-left: -1.2rem; */
}
.icon3 {
  height: 25px;
}
.icon4 {
  height: 25px;
}
.icon-second {
  height: 15px;
}
.normal1,
.checkupnow,
.good,
.normal2 {
  /* margin-top: 3rem; */
  /* margin-bottom: 0rem; */
  font-size: 13px;
}
.measureunit1,
.measureunit2,
.measureunit3,
.measureunit4 {
  font-size: 13px;
  /* margin: 0.1rem; */
}

.exercise_container {
  /* width: 100%;
    height: 225px;
    left: 6rem;
    position: relative; */

  /* display: contents; */
  /* background-color: transparent; */

  width: 100%;
  margin-top: 1rem;
  height: 9rem;
  left: 5rem;
  top: -3.8rem;
  /* background: red; */
  position: relative;
}

.exercise {
  /* position: absolute; */
  /* margin-left: 18.5rem; */
  /* padding: 0.7rem; */
  color: #ededed;
  font-family: "Orbitron";
  /* font-size: 1.3rem; */
  width: 100%;
  height: 16rem;
  /* top:4; */
  /* left: 259px; */
  /* top: 440px; */
  background: #16173b;

  background: linear-gradient(
    169.54deg,
    rgba(102, 35, 128, 0.7) -0.01%,
    rgba(104, 39, 182, 0.7) 99.32%
  );
  /* background: black; */
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
}
.exercise:hover {
  border: 2px solid #009eb9;
}
.exercise_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.exercise_Header > h1 {
  font-size: 1.5rem;
  letter-spacing: 2px;
}
.exercise_content {
  display: flex;
}

.main_exercise {
  width: 100%;
  height: 60%;
  /* background: red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.empty {
  /* position: absolute; */
  /* margin: 7px; */
  /* padding: 0.5rem; */
  color: #ededed;
  font-family: Orbitron;
  /* font-size: 1.5rem; */
  /* height:60%; */
  width: 40%;
  height: 100%;
  background: #0d0f2a;
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
}
.empty:hover {
  border: 2px solid #009eb9;
}
.flex_step {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.step {
  color: #ededed;
  font-size: 14px;
  font-family: Orbitron;
  width: 67%;
  background: #0d0f2a;
  padding: 8px 30px;
  /* border-radius: 50px; */
  display: flex;
  justify-content: end;
  align-items: center;
}
.step1:hover {
  border: 1px solid #009eb9;
}

.gym {
  box-sizing: border-box;
  /* position: absolute; */
  width: 80px;
  height: 24px;
  /* left: 17rem; */
  color: #00d7fb;
  background: #16173b;
  border: 0.5px solid #00d7fb;
  box-shadow: 0px 2px 1px rgba(64, 72, 82, 0.05);
  border-radius: 5px;
}
.gym:hover {
  border: 1px solid #009eb9;
}
.yoga {
  /* box-sizing: border-box; */
  /* position: absolute; */
  width: 74.55px;
  height: 24px;
  /* left: 22rem; */
  color: #00d7fb;
  background: #16173b;
  border: none;
  box-shadow: 0px 2px 1px rgba(64, 72, 82, 0.05);
  border-radius: 5px;
}
.yoga:hover {
  border: 1px solid #009eb9;
}
.checkbox1 {
  height: 17px;
  margin-left: 5rem;
  /* width: 22px; */
  accent-color: #00d7fb;
}
.checkbox2 {
  height: 17px;
  margin-left: 4.5rem;
  width: 18px;
  accent-color: #00d7fb;
}

.lunges,
.burpees,
.bicepcurls,
.squats {
  color: #ededed;
  /* font-size: 0.7rem; */
  margin: 5px;
  font-family: "Orbitron";
}
.heartrate {
  margin-left: 3.5rem;
  font-size: 13px;
}
.stepscovered {
  margin-left: 2.5rem;
  font-size: 13px;
}
.bloodpressure {
  margin-left: 1rem;
  font-size: 13px;
}
.water {
  margin-left: 6rem;
  font-size: 13px;
}

.budgeting {
  /* display: contents; */
  position: relative;
  /* margin-left: 18.5rem; */
  /* padding: 0.7rem; */
  background: black;
  /* font-size: 1.3rem; */
  color: #ededed;
  font-family: "Orbitron";
  width: 100%;
  height: 100%;
  top: 3rem;
  left: 5rem;
  /* left: 259px; */
  /* top: 655px; */
  /* background: #16173B; */
  background: linear-gradient(
    169.54deg,
    rgba(102, 35, 128, 0.7) -0.01%,
    rgba(104, 39, 182, 0.7) 99.32%
  );
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  overflow: hidden;
}
.budgeting:hover {
  border: 2px solid #009eb9;
}
.budget_container {
  display: flex;
  /* height: 100%; */
  width: 100%;
  justify-content: center;
  align-items: center;
}

.doughnut {
  /* /* height:200px; */
  width: 40%;
  height: 100%;
  display: flex;
  /* margin-left: 8.5rem; */
  /* margin-top: -2rem; */
  /* font-size: 15px; */
}
.value {
  /* margin-top: 100px; */
  /* line-height: 2; */
}
.budget-content {
  font-size: 10px;
  /* text-align: center;  */
}
.improvement_container {
  width: 100%;
  position: relative;
  display: flex;
  left: 4.5rem;
  /* background: red;*/
}
.improvement {
  /* position: absolute; */
  /* margin-left: 61.5rem; */
  /* padding: 0.5rem; */
  color: #ededed;
  font-family: "Orbitron";
  width: 40%;
  height: 20%;
  left: 200px;
  top: 655px;
  background: #16173b;
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
}
.improvement:hover {
  border: 2px solid #009eb9;
}
.list li {
  /* margin: 0.4rem;
    padding: 0.5rem; */
  font-size: 10px;
  text-align: center;
  background: linear-gradient(180deg, #9747ff 0%, rgba(151, 71, 255, 0) 100%);
  border-bottom: 2px solid #9747ff;
  border-radius: 8px;
  list-style: none;
  font-size: 1rem;
  padding: 5px;
  margin: 10px 0;
}
.list:hover {
  border: 2px solid #009eb9;
}
.list2 {
  margin: 0.4rem;
  padding: 0.5rem;
  height: 19px;
  text-align: center;
  background: linear-gradient(180deg, #9747ff 0%, rgba(151, 71, 255, 0) 100%);
  border-bottom: 2px solid #9747ff;
  border-radius: 8px;
}
.list2:hover {
  border: 2px solid #009eb9;
}
.list3 {
  margin: 0.4rem;
  padding: 0.5rem;
  height: 19px;
  text-align: center;
  background: linear-gradient(180deg, #9747ff 0%, rgba(151, 71, 255, 0) 100%);
  border-bottom: 2px solid #9747ff;
  border-radius: 8px;
}
.list3:hover {
  border: 2px solid #009eb9;
}
.checkup1 {
  /* position: absolute; */
  margin-left: 65.6rem;
  padding: 0.5rem;
  color: #ededed;
  text-align: center;
  font-family: "Orbitron";
  font-size: 8px;
  line-height: 20px;
  width: 18vw;
  height: 4vh;
  left: 435px;
  top: 659.5px;
  background: #16173b;
  box-shadow: 0px 4px 8px rgb(10 10 31 / 80%);
  border-radius: 8px;
  border-left: 1px solid #00ffff;
}
.checkup1:hover {
  border: 2px solid #009eb9;
}
.checkup2 {
  /* position: absolute; */
  margin-left: 65.6rem;
  padding: 0.5rem;
  color: #ededed;
  text-align: center;
  font-family: "Orbitron";
  font-size: 8px;
  line-height: 20px;
  width: 18vw;
  height: 4vh;
  left: 435px;
  top: 732px;
  background: #16173b;
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  border-left: 1px solid #fb4553;
}
.checkup2:hover {
  border: 2px solid #009eb9;
}
.checkup3 {
  /* position: absolute; */
  margin-left: 65.6rem;
  padding: 0.5rem;
  color: #ededed;
  text-align: center;
  font-family: "Orbitron";
  font-size: 8px;
  line-height: 20px;
  width: 18vw;
  height: 4vh;
  left: 435px;
  top: 800px;
  background: #16173b;
  box-shadow: 0px 4px 8px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  border-left: 1px solid #00ffff;
}
.checkup3:hover {
  border: 2px solid #009eb9;
}
.arrow {
  color: rgb(247, 243, 243);
  cursor: pointer;
  user-select: none;
  /* margin-left: 7rem; */
}
.arrow:hover {
  color: #33afeb;
}
.arrow1 {
  /* margin-left: 6rem; */
}
.arrow1:hover {
  color: #33afeb;
}
.arrow2 {
  /* margin-left: 9rem; */
}
.arrow2:hover {
  color: #33afeb;
}

table {
  /* margin: 1rem; */
  font-family: "Orbitron";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.09em;
  color: #ededed;
}
.col {
  /* margin: 1rem; */
}

@media screen and (max-width: 508px) {
  .left_part_appointment {
    display: none;
  }
  .popup {
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .healthcare-left {
    width: 100%;
    height: inherit;
  }
  .health_container {
    width: 100%;
  }
  .utilityhealthcare-backround {
    overflow: scroll;
    flex-wrap: wrap;
  }
  .helth {
    width: 100%;
  }
  .health-display {
    width: 100%;
    margin-left: -11rem;
  }
  .inner_health_display {
    width: 100%;
    flex-wrap: wrap;
  }
  .health-display1 {
    width: 90%;
  }
  .flex_step {
    display: none;
  }
  .empty {
    width: 90%;
  }
  .exercise_container {
    left: 0rem;
    width: 100%;
    overflow: hidden;
    height: 15rem;
  }
  .budgeting {
    top: 0;
    left: 0;
  }
  .lineChart_container {
    width: 100%;
    left: -0rem;
  }
  .xxx {
    width: 100%;
  }
  .chart {
    width: 95%;
  }
  .appointment {
    left: 0;
  }
  .improvement_container {
    left: 0;
  }
  .improvement {
    width: 100%;
  }
  .list {
    width: 100%;
  }
  .list li {
    width: 100%;
  }
}
