.utility {
  height: 100vh;
  background: linear-gradient(#191a40, #090b22);
}
.utilitys-list {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.utility-items {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  height: 220px;
  width: 70%;
  background: linear-gradient(#262f4f, #49005d3d);
  border-radius: 20px;
  color: #eaeaea;
  font-family: "Orbitron";
  font-size: 20px;
}

.utility-items:hover {
  border: 3px solid #33afeb;
  transform: scale(1.05);
  transition: 0.3s;
  cursor: pointer;
}

.row1 {
  width: 30%;
}
.row2 {
  width: 30%;
}
.row3 {
  width: 30%;
}

@media screen and (max-width: 950px) {
  .utility {
    height: 100%;
  }
  .utilitys-list {
    flex-wrap: wrap;
  }
  .utility-items {
    height: 8rem;
  }

  .row1 {
    width: 60%;
  }
  .row2 {
    width: 60%;
  }
  .row3 {
    width: 60%;
  }
}
