.preferences {
  height: 100vh;
  position: relative;
  overflow: hidden;
  /* background: linear-gradient(#191a40, #090b22); */
}

.background{
  background-color: #180682;
}
.settings-background {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-content {
  background: rgba(255, 255, 255, 0.171);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  margin: 20px 50px 40px 50px;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.settings-content {
  scrollbar-width: auto;
  scrollbar-color: #16173b;
}

/* Chrome, Edge, and Safari */
.settings-content::-webkit-scrollbar {
  width: 8px;
}

.settings-content::-webkit-scrollbar-track {
  background: inherit;
}

.settings-content::-webkit-scrollbar-thumb {
  background-color: #16173b;
  border-radius: 10px;
  border: none;
}

/* Settings Navbar */

.settings-navbar {
  /* background-color: transparent; */
  /* border: 2px solid transparent; */
  border-image: radial-gradient(#02d1ff, #ff00b8);

  
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 75%;
  height: 60px;
  margin: 20px 30px 10px 40px;
  border-radius: 4px;

  
}

.settings-item {
  font-size: 20px;
  font-family: "Oxanium";
  /* background-color: inherit; */
  border: none;
  font-weight:1000;
  /* color: #e81212; */
  padding: 4px 20px 4px 20px;
  border-radius: 10px;

  background: -webkit-linear-gradient(126deg, #02d1ff 0%, #ff00b8 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}
.settings-item:focus {
  background-color: #3c96bb;
  color: #16173b;
}



.settings-item:hover{
  background-color: #090b22;
}





@media screen and (max-width : 768px) {
  /* .plans{
      flex-direction: column;
  }
  .plan:nth-child(2){
      transform: none;
  } */

  .settings-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    height: 92vh;
  }
  .plan{
    flex-direction: column;
    justify-content: space-between;
  }

  .settings-background{
    width: 100vw;
  }
}