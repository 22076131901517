.Billing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.Billing-left {

    height: 100vh;
    width: 62vw;
    margin-top: 0px;
}

.Billing-right {

    height: 100vh;
    width: 30vw;
    margin-top: 0px;

}

tbody{
    column-gap: 1rem;
    margin: 0;
}
td{
    gap: 1rem;
}

.Billing-left,
.Billing-right {
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

.Account-usage {
    margin: 0px;
    height: 26px;
    display: inline;
    width: 155px;
    padding-left: 25px;
    font-family: 'Oxanium';
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: left;
    color: #EDEDED;
}

.chart1 {
    margin-left: 5.02%;
    margin-top: 2.28%;
    width: 55.10101vw;
    height: 29.511278195vh;
    border-radius: 10px;
    background: #16173B;
    border: 2px solid rgba(0, 215, 251, 1);
    background: linear-gradient(90deg, #006660ED -35%, #AC008630 59.62%);

}

.chart2 {
    margin-left: 5.02%;
    margin-top: 3.33%;
    height: 21.710526315vh;
    width: 55.10101vw;
    border-radius: 10px;
    border: 2px solid rgba(0, 215, 251, 1);
    background: #16173B;
    background: linear-gradient(90deg, #006660ED -35%, #AC008630 59.62%);
    border-left: 3px solid #00D7FB;
    /* #006660ED, #AC008630 */
    /* #06686294, #DB02AB00 */
}

.paymentHistory {
    margin-top: 1.88%;
    margin-left: 5.02%;
    font-family: "Oxanium";
    font-size: 1.5em;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #EDEDED;


}

.paymentHistory-Navbar {
    margin-top: 1.018%;
    margin-left: 5.02%;
    width: 55.10101vw;
    height: 3.24vh;
    background: rgba(20, 23, 33, 0.6);
    font-family: "Oxanium";
    font-size: 18px;
    font-weight: 300;
    color: #EDEDED;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}



.paymentHistory-Details {

    width: 55.10101vw;
    overflow: auto;
    margin-left: 5.02vw;
    font-family: "Oxanium";
    border-radius: 8px;
    font-size: 20px;
    font-weight: 400;
    line-height: 2.31vh;
    letter-spacing: 0em;
    color: #EDEDED;
    width: 80%;
    display: inline-block;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
}

.date {
    padding-left: 1.781vw;
    padding-top: 1.67vh;
}

.Details {
    padding-top: 1.67vh;
    padding-left: 6.51vw;
    width: 213px;
}

.amount {
    padding-top: 1.67vh;
    padding-left: 7.48vw;
    width: 134px;
}

.download {
    padding-top: 1.67vh;
    padding-left: 7.9vw;
    color: #36C4D1;
}


.student-Tier {
    margin-top: 3.88%;
    margin-left: 0.18%;
    height: 369px;
    width: 498px;

    background: linear-gradient(118.4deg, #49BAEA -13.45%, rgba(22, 23, 59, 0.57) 42.84%);
    border-top: 4px solid #2F8AB4;
    border-radius: 8px;
    /* box-sizing: border-box; */
    font-family: "Oxanium";

    display: flex;
    flex-direction: column;


}

.tier {
    padding-top: 186px;
    padding-left: 18px;
    width: 145px;
    height: 27px;
    left: 1256px;
    top: 371px;

    font-family: "Oxanium";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5em;
    line-height: 30px;

    color: #EDEDED;

}

.paragrapg {
    padding-top: 8px;
    padding-bottom: 6px;
    padding-left: 18px;
    width: 438px;
    height: 45px;
    left: 1256px;
    top: 405px;

    font-family: "Oxanium";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;
}

.footer {
    padding-left: 18px;
    width: 28.5rem;
    height: 7rem;
    left: 1238px;
    top: 466px;
    padding: 20px;
    background: rgba(217, 217, 217, 0.132);
    backdrop-filter: blur(10.395px);
    border-radius: 0px 0px 8px 8px;
    font-family: "Oxanium";
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;

}

.payment {

    background: transparent;
    /* background: linear-gradient(180deg, #33AFEB 0%, #2D708C 100%); */
    /* box-shadow: 1.0776892302749477e-15px 17.600000381469727px 35.20000076293945px 0px rgba(12, 30, 37, 0.401); */

    /* box-shadow: 4.454448860821431e-16px 7.274666786193848px 7.274666786193848px 0px rgba(75, 188, 234, 1) inset; */

    /* box-shadow: -4.454448860821431e-16px -7.274666786193848px 7.274666786193848px 0px rgba(41, 103, 128, 1) inset; */
    height: 60px;
    width: 498px;
    margin-top: 4.90%;
    border-radius: 8px;
    border: 2px solid #33AFEB;

}

.Make-payment {
    padding-left: 163px;
    height: 27px;
    width: 172px;
    left: 1401px;
    top: 615px;
    font-family: "Oxanium";
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(9, 11, 34, 1);

}

.add-method {
    height: 80px;
    width: 498px;
    margin-top: 3.33%;

    border-radius: 8px;
    border: 2px dashed rgba(47, 134, 173, 1);
    box-sizing: border-box;

}

.Barchart{
    height: 3rem;
    

}
.Payment-method {
    height: 27px;
    width: 31rempx;
    left: 1401px;
    top: 721px;
    font-family: "Oxanium";
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(237, 237, 237, 1);
    font-style: normal;
    padding-left: 145px;
    padding-top: 28px;
}

.BrowsePlan {
    height: 160px;
    width: 498px;
    margin-top: 7.07%;
    background: linear-gradient(90deg, #006660ED -35%, #AC008630 59.62%);
  
    border: 2px solid #00D7FB;
    border-radius: 8px;
    box-sizing: border-box;
}

.Browse {
    padding-left: 24px;
    padding-top: 87px;
    height: 24px;
    width: 356px;
    left: 1262px;
    top: 941px;
    font-family: "Oxanium";
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(237, 237, 237, 1);

}

.para {


    padding-left: 24px;
    height: 21px;
    width: 356px;
    left: 1262px;
    top: 969px;
    font-family: "Oxanium";
    font-size: 15px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #EDEDED;

}

.cross {
    height: 19.826561px;
    width: 49.192186px;
    left: 11.904296875px;
    top: 12.1923828125px;
    border-radius: 58px;
    color: rgba(206, 206, 206, 1);
    position: absolute;
    left: 96.07%;
    right: 101.07%;
    top: 4.87%;
    bottom: 95.28%;

}

button {
    background-color: rgb(22, 23, 59);
    color: aliceblue;
    border: none;
}