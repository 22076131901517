
.dialogclose{
    position: absolute;
   left: 760px;
   top: 5px;
   cursor: pointer;
}
.dialogclose img{
    height: 30px;
    cursor: pointer;
}
.dialogclose img:hover{
    transform: scale(1.1);
}
.pwr_reset_btn{
position: absolute;
width: 108px;
height: 36px;
top: 220px;
left: 120px;
margin: 1rem;
border: none;
font-size: 15px;
color:white;
background: inherit;
border-radius: 8px;
border: 1px solid #1A1B41;
cursor: pointer;
}
.keep_btn{
position: absolute;
width: 108px;
height: 36px;
top: 220px;
left: 0px;
margin: 1rem;
border: none;
font-size: 15px;
color:white;
background: inherit;
border-radius: 8px;
border: 1px solid #1A1B41;
cursor: pointer;
}.pwr_reset_btn:hover,.keep_btn:hover{
    background: linear-gradient(180deg, #00FFFF 0%, #009EB9 100%);
box-shadow: 9.22567e-16px 15.0667px 30.1333px rgba(0, 43, 50, 0.422), inset -3.93629e-16px -6.42844px 6.42844px #0090A8, inset 3.93629e-16px 6.42844px 6.42844px #00FFFF;
border: none;
color:#1A1B41;
}
.closepower{
position: absolute;
left: 220px;
bottom: 260px;
cursor: pointer;
}.closepower img {
    height: 28px;
    width: 28px;
    margin-left: -1.9px;
}.closepower img:hover {
transform: scale(1.1);
}
/* .threed.active,.framed.active{
    height: 50px;
}
.framed img.active{
height: 50px;
width: 80px;
} */
.power_title{
display: inline-block;
/* width: 134px;
height: 20px; */
margin: 1rem;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 20px;
color: #FFFFFF;

}
.power{
position: absolute;
z-index: 10;
width: 256px;
height: 299px;
border: 2px solid rgba(255, 255, 255, 0.5);
background-color: linear-gradient(to bottom right, rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0));
/* background-color: aqua; */
margin-top: -840px;
margin-right: 35px;
backdrop-filter: blur(10px);
border-radius: 30px;
}
.power_content{
    position: absolute;
width: 217px;
height: 102px;
left: 16px;
top: 79px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 25px;
color: #FFFFFF;
}
