@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


.myplans-about {
    display: inline-block;
    font-family: 'Oxanium';
    font-size: 1rem;
    margin-top: 2rem;
    margin-left: 34rem;
    /* margin-bottom: 5rem; */
    color: #FFFFFF;
    text-align: center;
}
.myplans-content{
    width: fit-content;
    margin-left: 2.8rem;
}

.h4{
    position: relative; 
    color: #33AFEB;
    width: 31rem;
    font-size: 1.5rem;
}
.about {
    font-family: 'Oxanium';
    font-size: 4rem;
    color: #FFFFFF;
    padding: 1px;
    line-height: 2;
}

.h1 {
    color: #33AFEB;
    font-family: 'Oxanium';
    margin: 0.5rem;
}

.container {
    height: auto;
    width: 1200px;
    margin: 5rem;
}

.card-div {
    display: grid;
    align-items: center;
    position: relative;
    left: 8rem;
    justify-content: space-between;
}




.slide {
    gap: 2rem;
    transform: scale(0.8);
    transition: transform 300ms;
    background: transparent;
    border-radius: 10px;
}

.activeSlide {
    transform: scale(0.9);
    /* background-color: #16173B; */
    border-top: 4px solid #3892b5;
    height: 100%;
    /* -webkit-box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.75); */
    /* -moz-box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.75); */
    /* box-shadow: 0px 7px 18px -3px rgba(0, 0, 0, 0.75); */
    border-radius: 10px;
    /* color: black; */
    background-image: var(--planCard);
    transform: scale(1.1);
    /* opacity: 1; */
}

/* @media screen and(max-width:600px) {
    myplans-features{
        width: 100%;
    }
    .slide.myplans-features{
        width: 10rem;
    }
    
}  */
.rating {
    margin-right: 7.5rem;
}

.myplans-heading {
    text-align: center;
    padding: 0rem;
    padding-top: 2px;
    padding-bottom: 1rem;
    line-height: 1;
}

p {
    text-align: center;
    margin-top: -5px;
    margin-bottom: 25px;
}

.myplans-plans {
    display: flex;
    margin: 0.5rem;
}

hr {
    background-color: '#fffff';
    margin-right: 2rem;
}

.next {
    position: absolute;
    padding: 10px;
    top: 40%;
    z-index: 1;
    right: 1.1rem;
    font-size: 1.5rem;
    color: rgb(254, 248, 248);
    cursor: pointer;
    user-select: none;
    border-radius: 25px;
    background: rgba(217, 217, 217, 0.102);
}

.next:hover {
    color: #47B2DE;
}

.prev {
    position: absolute;
    padding: 10px;
    z-index: 1;
    top: 40%;
    left: 0.1rem;
    font-size: 1.5rem;
    background: rgba(217, 217, 217, 0.102);
    cursor: pointer;
    color: white;
    user-select: none;
    border-radius: 25px;
    /* background-repeat: no-repeat;
  background-size: 300px 100px; */
    /* background-color: rgba(255, 255, 255, 0.171); */
    /* transform: matrix(0, 1, 1, 0, 0, 0); */
}

.prev:hover {
    color: #3892b5;
}

.myplans-features {
    box-sizing: border-box;
    padding: 1rem;
    /* background-image: var(--planCard); */
    color: white;
    gap: 200px;
    border: 2px solid transparent;
    /* border-image: linear-gradient(to bottom, #FF00E5, #00D1FF) 1; */
    border-image: linear-gradient(150deg, #02d1ff 0%, #ff00b8 100%) 1;
    font-family: 'Oxanium';
    margin-bottom: 5rem;
    position: relative;
    top: 36px;
}

.hello{
    border: none;
}
.t1 {
    padding-left: 2rem;
    padding: 1rem;
}

.t2 {
    padding: 1.5rem;
}

.t4 {
    padding: 9px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.myplan-button {
    margin: 2rem;
    border-radius: 5px;
    font-family: 'Oxanium';
    width: 200px;
    font-size: 20px;
    height: 45px;
    border: none;
    color: white;
    background: #860071;

}

.activeSlide .myplan-button{
    background: linear-gradient(#47B2DE,#2d6f8c72);
    color: #16173B;
    font-size: 22px;
    width: 220px;
    height: 50px;
    font-weight: 600;
}

.activeSlide .myplan-button:hover{
    animation:  size-increase 0.1s forwards;
    border: none;
}


.myplan-button:hover {
    border: 4px solid #47B2DE;
}

@keyframes size-increase {
    0%{
        font-size: 22px;
    }
    100%{
        font-size: 23px;
    }
}