.stores{
    width:100%;
    /* height:100vh; */
    background: linear-gradient(180deg, #191A40 -11.3%, #090B22 72.59%);
    /* background: black; */
    /* overflow-y: scroll; */
    /* overflow-x: scroll; */
    position: relative;
overflow: hidden;
}
.background{
    width:100%;
    height: 100%;
    position: absolute;
}
/* Purple Color */
.purple{
    position: absolute;
width: 1029px;
height: 924px;
left: 1501px;
top: -138px;

background: #FF00E5;
filter: blur(370px);
}


/* Blue Color */
.blue{
    position: absolute;
width: 955px;
height: 873px;
left: -611px;
top: 494px;

background: #4200FF;
filter: blur(354px);
}

.StoreAlignment{
    margin: 30px 0;
}


.storeContainer{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 11.63%, #000000 47.01%, #6B0091 78.12%, #000000 100%);
    width: 100%;
    /* height: 100vh; */
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.innerStoreContainer{
    width:90%;
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    /* background: blue; */
}
.store_Banner{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 365px;
    background: black;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
}
.store_Banner>img{
    position: absolute;
    left: 0px;
    top:0;
    right: 0;
    bottom: 0;
    /* z-index: 1; */
    width: 105%;
    margin: 0;

}
.store_Banner>.BannerText{
    width: 50%;
    height: 100%;
    position: absolute;padding-left: 50px;
    background: linear-gradient(105.39deg, rgba(47, 218, 255, 0.4) 2.09%, rgba(255, 255, 255, 0) 103.18%);
    backdrop-filter: blur(7.5px);
    -webkit-backdrop-filter: blur(7.5px);
}
.BannerText>h1{
    padding-bottom: 8px;
    /* position: absolute; */
    font-style: normal;
font-weight: 400;
font-size: 100.981px;
line-height: 83.9%;
/* or 132px */

letter-spacing: -0.025em;
text-transform: uppercase;

background: linear-gradient(97.95deg, #FD9601 5.88%, #34FFFF 82.18%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}
.BannerText>p{
    width: 70%;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.653);
    margin-top: 20px;
    /* background: red; */
    text-align: start;
    margin-left: 50px;
}



/*  */
.HeadTitle{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.HeadTitle>h1{
    font-style: normal;
font-weight: 500;
font-size: 59.6638px;
line-height: 78px;
/* identical to box height */

letter-spacing: 0.04em;

background: linear-gradient(90.68deg, #FF88F3 -6.77%, #07DADA 104.31%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}
.HeadTitle>h5{
    Font-style: normal;
font-weight: 500;
font-size: 18.387px;
line-height: 24px;
/* identical to box height */

letter-spacing: 0.04em;

color: rgba(255, 255, 255, 0.63);
}
.CardType3_Horizontal_Alignment{
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

   }


/* ==================================    */
.latestRelease{
 width: 100%;
 /* background: red; */
 /* height: 5; */
 display: flex;
 flex-direction: column;
justify-content: space-between;
box-sizing: border-box;
z-index: 5;

/* align-items: center;  */
}
.HeadingLatestRelease{
    
}
.Card_BG_gradient,.Card_Theme_BG,.Card_Recommended_BG{
    background:linear-gradient(red,rgb(9, 255, 0));
    display: flex;
    justify-content: center;
    align-items : center;

}
.Card_BG_gradient{
    width: 20%;
    height: 500px;
    margin: 40px;
    /* padding: 15; */

    border-radius: 18.1874px;
}

.CardLatestRelease{
    /* background: green; */
    width:98.5%;
    height: 99.5%;
    background: black;
    /* border-radius: 30px; */
    box-sizing: border-box;
    overflow: hidden;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    filter: drop-shadow(2.18249px 2.90999px 6.54747px rgba(0, 0, 0, 0.26));
    border-radius: 18.1874px;


}
.LRCardImg{
    margin: 0;
    width: 100%;
    height: 75%;
    position: relative;
    /* overflow: hidden; */
}
.LRCardImg>img{
    height: 100%;
       position: absolute;
       left: -5%;
}
.LRCardText{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.InnerLRCardText{

    width: 90%;
    height: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.TitleLRCardText{
    height: 100%;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    
}
.TitleLRCardText>h3{
    /* height: 20%; */

    /* font-size: 2rem; */
    font-style: normal;
font-weight: 600;
font-size: 1.5rem;
line-height: 20px;
letter-spacing: 0.09em;
color: white;

    /* background: blueviolet; */
}
.TitleLRCardText>h5{
    font-style: normal;
font-weight: 600;
font-size: 1.5rem;
/* line-height: 14px; */
/* identical to box height */

letter-spacing: 0.09em;

background: -webkit-linear-gradient(98deg, #FA00FF , #00C2FF 110.4%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}
.LRCardDownload{
    
    
    background-color: black;
    border-radius: 10px;
    /* background: blue; */
    width: 60%;
    height: 50px;
    padding: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* padding: 15px 10px; */
}
.LRCardDownload::before{
    content: "";
    position: absolute;
    top: -1%;
    right: -1%;
    left: -1%;
    bottom: -2%;
    background:linear-gradient(95deg,rgb(255, 255, 255) ,rgb(0, 255, 0) 100%);
    border-radius: 10px;
    z-index: -1;


}


.TextLRCardDownload>h3{
    background: linear-gradient(90.68deg, #a4ff9a -6.77%, #4dff44 104.31%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
}

/* ==================================================== */
.Tag_mainContainer{
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tags_container{
    width: 100%;
    height: 300px;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    gap: 20px
    ;justify-content: center
    ;align-items: center;
}
.upper_tags,.bottom_tags{
    display: flex;
    /* flex-wrap: wrap;     */
}
.upper_tags{
    justify-content: center;

}
.bottom_tags{
    width: 100%;
    /* background: black; */
    justify-content: center;
}

.tags_Card{
    width: 200px;
    height: 70px;
    border-radius: 50px;
    padding: 1px;
    /* background: blue; */
    background:linear-gradient(red,rgb(9, 255, 0));
    
    margin: 0 40px;
}
.tags{
    width:100% ;
    height: 100%;
    background: black;
border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* T E X T */
    color: white;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    font-weight: 500;
    text-transform: capitalize;
}




/* =======-=====================RecommendedPackage====================== */
/* =======-=====================RecommendedPackage====================== */
/* =======-=====================RecommendedPackage====================== */
/* =======-=====================RecommendedPackage====================== */
.RecommendedPackage{
    width: 100%;
    /* background: red; */
    height: 50%;
    display: flex;
    flex-direction: column;
   justify-content: center;
   gap: 30px;
   /* box-sizing: border-box; */
   
   /* align-items: center;  */
   }
   .HeadingRecommendedPackage{
       width: 100%;
       /* height: 30%; */
       /* background: blue; */
       display: flex;
       align-items: center;
       justify-content: center;
   }
.Card_Recommended_BG{
    width: 30%;
    height: 310px;
    border-radius: 10px;
    padding: 1px;
    margin: 15px;

}

   .CardRecommendedPackage{
    width: 100%;
    height: 100%;
       background:black;
       border-radius: 10px;
       box-sizing: border-box;
       overflow: hidden;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
   
   }
   .RecommendedPackageCardImg{
       margin: 0;
       width: 55%;
       height: 100%;
       overflow: hidden;
       position: relative;
   }
   .RecommendedPackageCardImg>img{
       box-sizing: border-box;
       height: 110%;
       position: absolute;
       left: -5%;
       /* top: -5%; */
       /* bottom: -5%; */
   }
   .RecommendedPackageCardText{
       width: 45%;
       height: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
   }
   .InnerRecommendedPackageCardText{
   
       width: 80%;
       height: 50%;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       align-items: center;
       
   }
   .TitleRecommendedPackageCardText{
       /* height: 100%; */

       /* justify-content: center; */
       
   }
   .TitleRecommendedPackageCardText>h3{
       /* height: 20%; */
   
       font-size: 
       1.5rem;
       color: white;
       /* background: blueviolet; */
   }
   .TitleRecommendedPackageCardText>h5{
    /* height: 20%; */
    font-style: normal;
    font-weight: 600;
    /* font-size: 1.5rem; */
    /* line-height: 14px; */
    /* identical to box height */
    
    letter-spacing: 0.09em;
    
    background: -webkit-linear-gradient(98deg, #FA00FF , #00C2FF 110.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    font-size: 1.2rem;

    /* background: blueviolet; */
}
   .Text_RecommendedCard{
    background:linear-gradient(95deg,rgb(255, 255, 255) ,rgb(0, 255, 0) 100%);
    
    
    border-radius: 5px;
    /* background: blue; */
    width: 90%;
    height: 40px;
    padding: 1.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
   }
   .RecommendedPackageCardDownload{
       display: flex;
       justify-content: center;
       align-items: center;
       /* background: blue; */
       /* width: 90%;
       padding: 10px 20px; */
       width: 100%;
       height: 100%;
       border-radius: 5px;
       background: #191A40;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       color: rgb(4, 244, 4);
   }

   /*====================== ULTIMATE VOICE PACK=================== */
   /*====================== ULTIMATE VOICE PACK=================== */
   /*====================== ULTIMATE VOICE PACK=================== */
   /*====================== ULTIMATE VOICE PACK=================== */

   .UltimateVoicePack{
    width: 100%;
    /* background: red; */
    height: auto;
    display: flex;
    flex-direction: column;
   justify-content: center;
   gap: 30px;
   /* box-sizing: border-box; */
   
   /* align-items: center;  */
   }
   .HeadingUltimateVoicePack{
       width: 100%;
       /* height: 30%; */
       /* background: blue; */
       display: flex;
       align-items: center;
       justify-content: space-between;
   }
   
/* ==================================== */
.Card_Theme_BG{
    
    width: 20%;
    height: 500px;
    margin:20px;
    border-radius: 30px;
    /* padding: 10; */
}
   .CardType3{
       background: rgb(0, 0, 0);
       width: 98.5%;
       height: 99.5%;
       /* box-sizing: border-box; */
       border-radius: 30px;
       overflow: hidden;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
   
   }
.CardType3_IMG{
       margin: 0;
       /* width: 100%; */
       height: 60%;
       overflow: hidden;
       position: relative;
   }
.CardType3_IMG img{
       /* box-sizing: border-box; */
       height: 100%;
       position: absolute;
       left: -5%;
       /* top: -5%; */
       /* bottom: -5%; */
   }
.CardType3_TextArea{
       /* width: 0%; */
       height: 50%;
       display: flex;
       justify-content: center;
       align-items: center;
       color: white;
   }
.CardType3_Inner_TextArea{
   
       width: 80%;
       height: 65%;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       align-items: center;
       text-align: center;
       
   }
.CardType3_H35{
       /* height: 100%; */

       /* justify-content: center; */
       
   }
.CardType3_H35>h3{
       /* height: 20%; */
   
       font-size: 
       1.5rem;
       /* background: blueviolet; */
   }
.CardType3_H35>h5{
    /* height: 20%; */

    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    /* line-height: 14px; */
    /* identical to box height */
    
    letter-spacing: 0.09em;
    
    background: -webkit-linear-gradient(98deg, #FA00FF , #00C2FF 110.4%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* background: blueviolet; */
}
   
.CardType3_GetNow{
       display: flex;
       justify-content: center;
       align-items: center;
       /* background: blue; */
       width: 90%;
       padding: 10px 20px;
   }
