.sign-up {

    background-color: #1A1B41;
    display: flex;
    color: #ffffff;
    font-size: 48px;
    text-align: center;
    overflow: hidden;
    height: 100vh;
}

.progress_bar {
    width: 250px;
    height: 2px;
    background-color: #3D3E5D;
    margin-right: auto;
    margin-left: auto;
}

.thirty_percent {
    animation: thirty forwards 500ms linear;
    height: 100%;
    background-color: #00D7FB;
}

.seventy_percent {
    animation: seventy forwards 500ms linear;
    height: 100%;
    background-color: #00D7FB;
}
.hundred_percent {
    animation: hundred forwards 500ms linear;
   
    height: 100%;
    background-color: #00D7FB;
}


.email_id p,
.nickname p,
.password p,
.confirm_password p {
    font-family: 'montserrat-m';
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 90px;
    margin-top: 9px;
}

.email_id,
.nickname,
.password,
.confirm_password {
    margin-top: 30px;
    margin-bottom: 20px;
}

.email_id input,
.nickname input,.password input,
.confirm_password input {
    width: 370px;
    height: 50px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: 'Montserrat';
    font-size: 16px;
    background-color: #3D3E5D;
    border: 3px solid #00D7FB;
    border-radius: 10px;
}

.email_id input:active,
.nickname input:active,
.password input:active,
.confirm_password input:active {
    border: 3px solid #007589;
    border-radius: 10px;
}

.email_id input::placeholder,
.nickname input::placeholder,
.password input::placeholder,
.confirm_password input::placeholder {
    color: #EDEDED;
    font-size: 16px;
    font-family: 'montserrat-l';
}

.password_rule{
    margin-top: 8px;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: 'montserrat-l';
    font-size: 10px;
    text-align: left;
}
.line {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border-bottom:0.1px solid rgba(237, 237, 237, 0.7);
  width: 90%;
}

.next1 button {
    height: 72px;
    width: 370px;
    font-family: 'montserrat-sb';
    font-size: large;
    border: none;
    border-radius: 3px;
    background-image: linear-gradient(#00F8FA, #009EB9);
    color: black;
}

.next1  :hover{
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0 0 30px rgba(74, 172, 228, 0.5);
}

.next3 button {
  height: 72px;
  width: 370px;
  font-family: 'montserrat-sb';
  font-size: large;
  border: none;
  border-radius: 3px;
  background-image: linear-gradient(#00F8FA, #009EB9);
  color: black;
}

.next3 button[disabled]{
  cursor: not-allowed;
}

.next3 button:not([disabled]):hover{
  cursor: pointer;
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0 0 30px rgba(74, 172, 228, 0.5);
}

.link_to_login {
    margin-top: 10px;
    font-size: 12px;
    font-family: 'montserrat-l';
    margin-bottom: 30px;
}

.link_to_login a {
    font-weight: 800;
    color: #B8F5FF;
    text-decoration: none;
}

.email_id{
    animation: fadeInleft 1s;
}
.nickname{
    animation: fadeInleft 1500ms;
}
.line_link{
    animation: fadeInleft 2000ms;
}
.next1{
    animation: fadeInleft 2000ms;
}
.username{
  animation: fadeInleft 500ms;
}
.password{
    animation: fadeInleft 1s;
}
.confirm_password{
    animation: fadeInleft 1500ms;
}
@keyframes thirty {
    from{
      width: 0%;
    }
    to{
      width: 30%;
    }
  }
  @keyframes seventy {
    from{
      width: 30%;
    }
    to{
      width: 70%;
    }
  }
  @keyframes hundred {
    from{
      width: 70%;
    }
    to{
      width: 100%;
    }
  }
  @keyframes visbility{
    from{visibility: hidden;}
    to {visibility: visible;}
  }
  @keyframes fadeInleft {
    from {
    
      opacity: 0;
      transform: translateX(300px);
    }
    to {
        visibility: visible;
      opacity: 1;
    }
  }

  .terms-CheckMark input[type='checkbox'] {
    background-color: #3d3d3d;
    height: 25px;
    width: 25px;
    border-radius: 6px;

    
}

.terms-CheckMark input[type='checkbox']:hover {
  accent-color: #3d3d3d;
}

.terms-CheckMark input[type='checkbox']:checked {
  accent-color: #3D3E5D;
}
.terms-CheckMark{
  margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: 'montserrat-l';
    border-radius: 6px;
}
.check{
  
    margin-left: 10px;
    
} 

.terms,.privacy{
  color:rgba(0, 215, 251, 1);
  cursor: pointer;

}

.ToastSignup{
  height: 42px;
  width: 300px;
  font-size: 18px;
  padding-bottom: 55px;
}