.settings{
    width: 100%;
    height: 100vh;
    background:linear-gradient(#191A40,#090B22);
    position: relative;
    overflow: hidden;
}

.settings-background{
    height: 100%;
    width: 100%;
    background: red ;  
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.settings-content{
  height: 90%;
  width: 75%;
  /* background: red; */
    /* background: rgba(255, 255, 255, 0.171); */
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    /* margin: 40px 50px 40px 50px; */
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    
    

}
 /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .settings-content{
    /* scrollbar-width: auto; */
    /* scrollbar-color: #16173B; */
  }

  /* Chrome, Edge, and Safari */
  .settings-content::-webkit-scrollbar {
    width: 8px;
  }

  .settings-content::-webkit-scrollbar-track {
    background: inherit;
  }

  .settings-content::-webkit-scrollbar-thumb {
    background-color: #16173B;
    border-radius: 10px;
    border: none;
  }

/* Settings Navbar */

.settings-navbar{
    /* background-color: #16173B; */
    justify-content: left;
    border: 2px solid blue;
    

}

.settings-item{
    font-size: 20px;
    font-family: 'Oxanium';
    background-color: inherit;
    border: none;
    color: #FFFFFF;
    padding: 4px 20px 4px 20px;
    border-radius: 10px;
    cursor: pointer;

    /* border-radius: 10px;
  background: -webkit-linear-gradient(126deg, #02d1ff 0%, #ff00b8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.settings-item:focus{
    background-color: #3C96BB;
    color: #16173B;
   
}

.settingNav{
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  border-image: linear-gradient(200deg, #02d1ff 0%, #ff00b8 100%) 1;
  background-color: transparent;
  border: 2px solid transparent;
}



.settingNav .settings-navbar  li{
  transition: 0.5s ease;
  list-style: none;

}

.settingNav .settings-navbar li .active{
  /* background-color: #3C96BB;
   */
    color: #16173B;
}

.backbutton{
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 0px;
  cursor: pointer;
  position: absolute;
}