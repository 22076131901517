.all-devices{
    display: flex;
}
.devices-left{
    border-right: 1px solid #979797;
    width: 60%;
}
.devices-right{
    width: 40%;
}
.devices-method-list {
    display: flex;
    list-style-type: none;
    margin-left: 50px;
    margin-top: 30px;
}

.devices-method-item {
    width: 100px;
    margin-right: 30px;
    /* border: 1px solid #3C96BB; */
    border-radius: 30px;
    background-color: #1C1F2C;
    font-family: 'Oxanium';
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    padding: 20px 30px 20px 30px;
}

.devices-method-item:hover{
    transform: scale(1.02);
    transition: transform 0.7s;
    cursor: pointer;
    border: 3px solid #33AFEB;
}


.desktop-user{
    width: 250px;
    height: 120px;
    margin: 20px 50px 20px 50px;
    background-color: #272740;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.desktop-user img{
    height: 100px;
}

.desktop-user:hover{
    transform: scale(1.02);
    transition: transform 0.7s;
    cursor: pointer;
    border: 3px solid #33AFEB;
}

.device-statistics{
    background-color: #16173B;
    border-radius: 20px;
    height: 300px;
    width: 400px;
    margin-left: 50px;
    margin-top: 50px;
}
.device-statistics .content-subtitle{
    color: #979797;
}
.statistics-graph{
    margin-top: 30px;
    display: flex;
   align-items: flex-end;
   font-family: 'IBM';
}
.device-statistics:hover{
    transform: scale(1.02);
    transition: transform 0.7s;
    cursor: pointer;
    border: 3px solid #33AFEB;
}
.graph-line1{
    height: 120px;
    width: 40px;
    background-color: #3B3C5A;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #FFFFFF;
}
.graph-line2{
    height: 140px;
    width: 40px;
    background-color: #3B3C5A;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #FFFFFF;
}
.graph-line3{
    height: 90px;
    width: 40px;
    background-color: #3B3C5A;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #FFFFFF;
}
.battery-percentage-graph,.cpu-percentage-graph{
    display: flex;
    font-family: 'IBM-m';
    font-size: 20px;
    align-items: center;
    color: #FFFFFF;
}
.battery-graph,.cpu-graph{
    display: flex;
    width: 300px;
    margin-left: 50px;
    margin-right: 20px;
}
.battery-linec{
    background-color: #EA267A;
    height: 40px;
    width: 15px;
    margin: 4px;
}
.battery-line{
    background-color: #442968;
    height: 40px;
    width: 15px;
    margin: 4px;
}

.invitation{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.invitation .content-subtitle{
    margin-top: 0px;
    color: #979797;
}

.accept-reject{
    display: flex;
    margin-left: 80px;
}
.accept{
    margin-left: 30px;
    margin-right: 30px;
}
.accept-btn{
    border: none;
    height: 40px;
    width: 140px;
    font-family: 'Oxanium';
    font-size: 20px;
    border-radius: 10px;
    background: linear-gradient(to top,#2D708C, #47B2DE);
    
}

.reject-btn{
    border: none;
    height: 40px;
    width: 140px;
    font-family: 'Oxanium';
    font-size: 20px;
    border-radius: 10px;
    border: 2px solid #4BBBEA;
    background-color: #16173B;
    color: #FFFFFF;
}

.accept:hover,
.reject:hover{
transform: scale(1.05);
transition: transform 0.7s;
cursor: pointer;
}



@media screen and (max-width : 950px){
    .all-devices{
        flex-direction: column;
    }
    .devices-left{
        width: 100%;
    }
    .devices-right{
        width: 100%;
    }
    .devices-method-list{
        flex-wrap: wrap;
    }

    .device-statistics{
        width: 100%;
        margin-left: 0rem;
    }

    .battery-percentage{
        width: 100%;
    }
    
}