@font-face {
    font-family: 'play';
    src: url('../assets/fonts/play.regular.ttf');
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    height: 100vh;
  }
  
  .outer {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    overflow: hidden;
  }
  
  .outer img,
  .inner img,
  .middle img {
    width: 65%;
  }
  
  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
  
  
  }
  
  /* .lines{
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    position: absolute;
  } */
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    position: absolute;
  }
  
  .count {
    font-family: 'play';
    font-size: 32px;
    color: #B8F5FF;
    z-index: 5;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .l-assets {
    font-family: 'play';
    font-size: 12px;
    color: #B8F5FF;
  }
  .branch-1{
    margin-top: -150px;
    opacity: 50%;
  }
  .branch-2{
    margin-top: 150px;
    opacity: 50%;
  }
  .branch-3{
    display: flex;
    justify-content: right;
    margin-right: 220px;
    margin-top: 170px;
    position: absolute;
    opacity: 50%;
  }
  .branch-4{
    display: flex;
    justify-content: right;
    margin-right: 240px;
    margin-top: 170px;
    position: absolute;
    opacity: 50%;
  }
  .branch-5{
    display: flex;
    justify-content: right;
    margin-right: 228px;
    position: absolute;
    margin-bottom: 160px;
    opacity: 50%;
  }
  
  .branch-3 .circle, .branch-5 .circle{
    margin-top: -3px;
    margin-right: 170px;
  }
  
  .branch-4 .diagnol-1{
    margin-right: 42px;
  }
  .branch-4 .line-2{
    margin-top: 42px;
    margin-right: 143px;
  }
  .branch-4 .arrow{
    margin-right: 194px;
    margin-top: 39px;
  }
  .diagnol-1{
    width: 120px;
    margin-left: -27px;
    border-bottom: 2px solid #05DCFF;
    position: absolute;
    transform: rotate(-45deg);
  }
  
  .line-1 {
    display: block;
    border-bottom: 2px solid #05DCFF;
    position: absolute;
    margin-top: -43px;
    margin-left: 76px;
  }
  .line-2 {
    display: block;
    border-bottom: 2px solid #05DCFF;
    position: absolute;
    margin-left: -29px;
  }
  .arrow{
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    
    margin-left: 140px;
    margin-top: -3px;
    position: absolute;
  }
  .circle {
    height: 9px;
    margin-left: 194px;
    margin-top: -46px;
    background-color: #05DCFF;
    border-radius: 50%;
    position: absolute;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .outer img {
      animation: logo-c-spin infinite 20s linear;
      padding: 0;
    }
  
    .middle img {
      animation: logo-a-spin infinite 10s ease;
      
      padding: 0;
    }
  
    .inner img {
      animation: logo-c-spin infinite 7s ease;
      padding: 0;
    }
  
    .line-1{
      animation: grow forwards 500ms linear;
      
      padding: 0;
    }
    .line-2{
      animation: grow-e forwards 500ms linear;
    }
    .branch-4 .line-1{
      animation: grow-s forwards 500ms linear;
    }
    .branch-4 .line-2{
      animation: grow-s forwards 500ms linear;
    }
  
  
    .circle{
  
      animation: grow-c forwards 50ms linear;
      animation-delay: 500ms;
      padding: 0;
    }
    .arrow{
  
      animation: grow-a forwards 50ms linear;
      animation-delay: 500ms;
      padding: 0;
    }
    .branch-4 .arrow{
      animation: grow-a-r forwards 50ms linear;
      animation-delay: 500ms;
    }
  }
  
  
  
  @keyframes logo-c-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes logo-a-spin {
    from {
      transform: rotate(360deg);
    }
  
    to {
      transform: rotate(0deg);
    }
  }
  
  @keyframes grow {
    from{
      width: 0%;
    }
    to{
      width: 120px;
    }
  }
  @keyframes grow-s {
    from{
      width: 0%;
    }
    to{
      width: 60px;
    }
  }
  @keyframes grow-e {
    from{
      width: 0%;
    }
    to{
      width: 170px;
    }
  }
  @keyframes grow-c {
    from{
      width: 0px;
    }
    to{
      width: 9px;
    }
  }
  @keyframes grow-a {
    from{
      border-left: 0px;
    }
    to{
      border-left: 10px solid #05DCFF;
    }
  }
  @keyframes grow-a-r {
    from{
      border-right: 0px;
    }
    to{
      border-right: 10px solid #05DCFF;
    }
  }