.devices{
  width: 100%;
    height: 100vh;
    background:linear-gradient(#191A40,#090B22);
  overflow: hidden;
  position: relative; 
  }

.devices-background{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.devices-content{
    background: rgba(255, 255, 255, 0.171);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    /* margin: -50px 50px 50px 50px; */
    height: 80%;
    width: 75%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    

}
 /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .devices-content{
    scrollbar-width: auto;
    scrollbar-color: #16173B;
  }

  /* Chrome, Edge, and Safari */
  .devices-content::-webkit-scrollbar {
    width: 8px;
  }

  .devices-content::-webkit-scrollbar-track {
    background: inherit;
  }

  .devices-content::-webkit-scrollbar-thumb {
    background-color: #16173B;
    border-radius: 10px;
    border: none;
  }

/* devices Navbar */
.devicesnav{
  display: flex;
  justify-content: center;
}

.devices-navbar{
   
    background-color: #16173B;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    height: 60px;
    /* margin: 20px 30px 10px 40px; */
    border-radius: 4px;

}

.devices-item{
    font-size: 20px;
    font-family: 'Oxanium';
    background-color: inherit;
    border: none;
    color: #FFFFFF;
    padding: 15px 30px;
    border-radius: 10px;
}
.devices-item:focus{
    background-color: #3C96BB;
    color: #16173B;
}
.devicesnav .devices-navbar li{
  transition: 0.5s ease;
  list-style: none;
}

.devicesnav .devices-navbar li .active{
  background-color: #3C96BB;
    color: #16173B;
}
.backbutton{
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

.devices-navbar-item button:hover{
  
    cursor: pointer;
}


@media screen and (max-width : 950px){
  .devices-content{
    overflow-y: scroll;
    overflow-x: hidden;
  }
}