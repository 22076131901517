.landing {
  background: linear-gradient(252.27deg, #141984 -0.49%, #660050 100.42%);
  height: 100vh;
  /* display: flex; */
  animation: fadeIn 3s;
  /* position: absolute; */
  overflow: hidden;
}
/* @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
} */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
/* =========================================================================================== */
:root {
  --InnerHomeContainer_HEIGHT: 95;
  --InnerHomeContainer_WIDTH: 96.5;
}

:root {
  --landingNavBar_Height: 6;
  --MainHomeContainer_Height: calc(100 - (var(--landingNavBar_Height)));
  --LeftHomeContainer_width: 75;
  --RightHomeContainer_width: calc(100 - (var(--LeftHomeContainer_width) + 2));
}

.landingNavBar {
  width: 100%;
  height: calc(var(--landingNavBar_Height) * 1%);
  display: flex;
  justify-content: center;
  justify-content: space-evenly;
}
.RightNavBar {
  width: 65%;
  /* background: black; */

  display: flex;
  justify-content: center;
  align-items: center;
}
.LeftNavBar {
  width: 30%;
  /* background: black; */
  gap: 30px;

  display: flex;
  justify-content: end;
  align-items: center;
}
.SearchBar,
.Notification,
.Profile {
  /* background: linear-gradient(18deg,red,blue); */
  border-radius: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SearchBar {
  width: 50%;
  padding: 1px;
}
.Inner_SearchArea {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: black;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
}
.Inner_SearchArea .SearchIcon {
  color: white;
  font-size: 1.5rem;
}
.Inner_SearchArea .inputText {
  height: 100%;
  outline: none;
  background: transparent;
  color: white;
  border: none;
  width: 80%;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  text-transform: capitalize;
}

.Notification {
  width: 40px;
  border-radius: 10px;
}
.Profile {
  width: 40px;
}
.MainHomeContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  /* background: red; */
  height: calc(var(--MainHomeContainer_Height) * 1%);
  width: 100%;
}
.InnerHomeContainer {
  width: calc(var(--InnerHomeContainer_WIDTH) * 1%);
  height: calc(var(--InnerHomeContainer_HEIGHT) * 1%);
  display: flex;

  justify-content: space-evenly;
  /* background: #ed7803; */
}
.RightHomeContainer,
.LeftHomeContainer {
  background: rgba(0, 0, 0, 0.39);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(23.5px);
  -webkit-backdrop-filter: blur(23.5);
  border-radius: 27.2826px;
}

.RightHomeContainer {
  width: calc(var(--RightHomeContainer_width) * 1%);
  height: 100%;
  /* background: blue; */
}
.LeftHomeContainer {
  width: calc(var(--LeftHomeContainer_width) * 1%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.InnerLeftHomeContainer {
  width: 98%;
  height: 98%;
}
/*  */
.Home_Banner,
.heading2,
.Home_Store,
.Home_RecentlyViewed {
  width: 100%;
}
/*  */
.Home_Banner {
  height: 35%;
  background: url(),
    linear-gradient(
      92.82deg,
      rgba(255, 255, 255, 0.3828) -1.69%,
      rgba(255, 255, 255, 0.2706) 101.53%
    );
  box-shadow: 0px 4.19732px 4.19732px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(26.7579px);
  -webkit-backdrop-filter: blur(26.7579px);
  /* Note: backdrop-filter has minimal browser support */
  overflow: hidden;
  border-radius: 27.2826px;
}
.Home_Banner > img {
  width: 105%;
  height: 105%;
}
.Quickaccess {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.QuickCard {
  /* min-width: 20%; */
  /* height: 75%; */
  width: auto;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #00ffe0 0%, #ff00f5 100%);
  /* this {Padding} :is Use for border */
  padding: 2px 1.8px;
}
.TextQuickCard {
  /* width: 100%;
    height: 100%; */
  background: rgb(24, 24, 24);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 35px;
}
.TextQuickCard > h2 {
  background: -webkit-linear-gradient(#00f6d8 40%, #ffffff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.Home_Store {
  height: 30%;
  padding-bottom: 10px;
  /* background: black; */
  text-align: center;
}
.Home_Store h1 {
  /* font-family: 'K2D'; */
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 20px;
  /* identical to box height, or 32px */

  letter-spacing: 0.5em;
  background: -webkit-linear-gradient(90.68deg, #ff88f3, #07dada 104.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Home_StoreCard {
  /* width: 100%; */
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.Card {
  width: 32%;
  height: 77%;
  background: linear-gradient(90deg, #00eeff9a 0%, #cc00ff 100%);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border: 2px solid transparent;
  box-shadow: 1.89907px 2.53209px 5.69721px rgba(0, 0, 0, 0.26);

  /* box-shadow: 1.89907px 2.53209px 5.69721px rgba(0, 0, 0, 0.26); */
  /* border-radius: 15.8256px; */
}
.ImageCard {
  width: 55%;
  height: 100%;
  background: #ed7803;
  overflow: hidden;
}
.ImageCard > img {
  height: 100%;
  /* width: 100%; */
}

.ContentCard {
  width: 45%;
  height: 100%;
  background: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TextContentCard {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.TextContentCard h3 {
  color: white;
}
.TextContentCard h4 {
  background: -webkit-linear-gradient(93.06deg, #fa00ff 0.06%, #00c2ff 110.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.TextContentCard p {
  font-size: 0.8rem;
  font-weight: 500;
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 163, 255, 0.69) 0%,
    rgba(255, 255, 255, 0.69) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.DownloadBTn_StoreCard {
  width: 80%;
  height: 30px;
  border: 10px solid;
  border-width: 2px;
  border-image-slice: 1;
  color: rgba(44, 198, 5, 0.92);
  border-image-source: linear-gradient(
    to left,
    rgba(44, 198, 5, 0.92),
    #46f4ff
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_RecentlyViewed {
  height: 25%;
  text-align: center;
}
.Home_RecentlyViewed > h1 {
  font-size: 1.5rem;
  background: linear-gradient(90.72deg, #57bfe2 2.18%, #fa00ff 96.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 20px 0;
}
.RcentCard {
  width: 32%;
  height: 100px;
  background: #090b21;
  /* background: linear-gradient(90deg,#00eeff9a 0%, #CC00FF 100%); */
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border: 2px solid transparent;
  box-shadow: 1.89907px 2.53209px 5.69721px rgba(0, 0, 0, 0.26);
}
.logoRcentCard {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.logoRcentCard:after {
  content: " ";
  width: 2px;
  opacity: 0.5;
  height: 50%;
  background: linear-gradient(90deg, #00eeff9a 0%, #cc00ff 100%);
  position: absolute;
  right: 0;
}
.logoRcentCard > img {
  width: 50%;
  height: 50%;
}
.RcentContentCard {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  /* background: #00C2FF; */
}
.RcentTextContentCard {
  /* background: #00F6D8; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ============================================================== */
/* ============================================================== */
/* ============================================================== */
/* ============================================================== */
/* ============================================================== */
/* ============================================================== */
/* ============================================================== */
/* ============================================================== */
