.account{
    height: 100vh;
    background:linear-gradient(#191A40,#090B22);
}
.accounts-list{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.account-items{
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    height: 220px;
    width: 240px;
    background: linear-gradient(#262F4F,#49005d3d);
    border-radius: 20px;
    color: #EAEAEA;
    font-family: 'Orbitron';
    font-size: 20px;

}

.account-items:hover{
    border: 3px solid #33AFEB;
    transform: scale(1.05);
    transition: 0.3s;
    cursor: pointer;
}