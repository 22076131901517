:root {
  --Gradient_Border_Finance_left: -2px;
}

.Finance {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(#191a40, #090b22);

  position: relative;

  overflow: hidden;
}
.Finance_container {
  width: 100vw;
  gap: 1rem;
  height: 100%;
  padding-left: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: scroll;
  align-items: center;
  /* overflow: hidden; */
}
.backbutton1 {
  /* margin-top: 12px;
    margin-left: 10px; */
  position: absolute;
}
.Finance-left {
  /* background: linear-gradient(18deg, rgb(96, 255, 52), rgb(255, 0, 247)); */

  /* border: 2px solid rgb(2, 2, 2); */
  border-radius: 16px;
  width: 22%;
  height: 90%;
  position: relative;
  margin-right: 20px;
  padding: 1px;
}
.Finance-left_INNER {
  border-radius: 16px;
  padding-top: 50px;
  padding: 10px 0;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  background: transparent;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Finance-left::before {
  content: "";
  position: absolute;
  /* width: 100%;
    height: 100%; */
  top: -50%;
  right: var(--Gradient_Border_Finance_left);
  bottom: calc(var(--Gradient_Border_Finance_left) - 0.5px);
  left: var(--Gradient_Border_Finance_left);
  border-radius: 10px;
  background: linear-gradient(18deg, rgb(96, 255, 52), rgb(255, 0, 247));
  z-index: -1;
}

/* .Finance-left:hover {
  border: 3px solid #33afeb;
  transform: scale(1.02);
  background: rgb(0, 0, 0);
} */
.Head_Investment {
  background: linear-gradient(
    90deg,
    rgba(0, 215, 251, 0.29) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  cursor: pointer;
}
.Investment {
  /* width: 23.43vw; */
  /* margin-top: 5.61%; */
}

.Investment-heading {
  font-family: "Orbitron";
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3.77vh;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-left: 5.6%; */
  margin-top: 7%;
}

.Investment-Button {
  border: none;
  background: none;
  margin-left: 37%;
}

.Investment-Dropdown {
  width: 100%;
  margin-left: 1rem;
  /* background: linear-gradient(0deg, #16173b, #16173b),
    linear-gradient(0deg, #33afeb, #33afeb); */
  padding-top: 4%;
  /* background: green; */
}

.Fixed-Deposit,
.Shares,
.Real-State {
  margin-top: 1%;
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-left: 4%; */
}

.fixed-dposit-bar,
.Shares-bar,
.Real-State-bar {
  width: 95%;
  /* height: 0.925vh; */
  background: rgba(45, 46, 76, 1);
  border-radius: 6px;
  /* margin-left: 4%; */
  margin-top: 4%;
  margin-bottom: 4%;
}

.fixed-dposit-bar-progress {
  width: 80%;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.Shares-bar-progress {
  width: 7%;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.Real-State-bar-progress {
  width: 50%;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.assets {
  /* width: 23.43vw; */
  margin-top: 5.61%;
}

.Head_assets {
  background: linear-gradient(
    90deg,
    rgba(0, 215, 251, 0.29) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  cursor: pointer;
}

.assets-Heading {
  font-family: "Orbitron";
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3.77vh;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-left: 5.6%; */
  margin-top: 5%;
}

.assets-button {
  border: none;
  background: none;
  margin-left: 52.8%;
}

.assets-Drop-down {
  /* background: linear-gradient(0deg, #16173b, #16173b),
    linear-gradient(0deg, #33afeb, #33afeb); */
  padding-top: 4%;
  margin-left: 1rem;
}

.asset1,
.asset2 {
  margin-top: 1%;
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-left: 4%; */
}

.asset1-bar,
.asset2-bar {
  width: 99%;
  height: 0.925vh;
  background: rgba(45, 46, 76, 1);
  border-radius: 6px;
  /* margin-left: 4%; */
  margin-top: 4%;
  margin-bottom: 4%;
}

.asset1-bar-progress {
  width: 50%;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.asset2-bar-progress {
  width: 19%;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.liabilites {
  /* width: 23.43vw; */
  margin-top: 5.61%;
}

.Head_liabilites {
  background: linear-gradient(
    90deg,
    rgba(0, 215, 251, 0.29) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  cursor: pointer;
}

.liabilites-Heading {
  font-family: "Orbitron";
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 3.77vh;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-left: 5.6%;
    margin-top: 5%; */
}

.liabilites-button {
  border: none;
  background: none;
  margin-left: 46.5%;
}

.Liabilites-Dropdown {
  /* background: linear-gradient(0deg, #16173b, #16173b),
    linear-gradient(0deg, #33afeb, #33afeb); */
  padding-top: 4%;
  margin-left: 1rem;
}

.liabilites1,
.liabilites2 {
  margin-top: 1%;
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-left: 4%; */
}

.liabilites1-bar,
.liabilites2-bar {
  width: 99%;
  height: 0.925vh;
  background: rgba(45, 46, 76, 1);
  border-radius: 6px;
  /* margin-left: 4%; */
  margin-top: 4%;
  margin-bottom: 4%;
}

.liabilites1-bar-progress {
  width: 10%;
  height: 0.925vh;
  background: rgba(182, 59, 182, 1);
  border-radius: 6px;
}

.liabilites2-bar-progress {
  width: 17%;
  height: 0.925vh;
  background: rgba(182, 59, 182, 1);
  border-radius: 6px;
}
/*  */
:root {
  --Finance-right_HEIGHT: 88;
  --quick_finance_detail: calc((100 - var(--Finance-right_HEIGHT)));
  --Finance_row: calc((100 - var(--quick_finance_detail)));

  --Finance-right-first: 100;
  /* For Change Height Just Change ' *Number Of Times ' */
  --Income: calc(var(--Finance-right-first) * 30 / 100);
  --Budgeting: calc(var(--Finance-right-first) - var(--Income));

  --Finance_Right_second: 100;
  --Expenses: calc(var(--Finance_Right_second) * 30 / 100);
  --spending_Limits: calc(var(--Finance_Right_second) * 19 / 100);
  --Savings: calc(
    var(--Finance_Right_second) - (var(--Expenses) + var(--spending-Limits))
  );
}
/*  */

:root {
  --Finance_right_WIDTH: 70;
  /* --row2_width: calc(100/4); */
}

.Finance-right {
  /* margin-top: 2.5%; */
  width: calc(var(--Finance_right_WIDTH) * 1%);
  height: calc(var(--Finance-right_HEIGHT) * 1%);

  /* background:green; */
  z-index: 2;
}
:root {
  --quick_finance_details__WIDTH: 100;
  --Networth: calc(var(--quick_finance_details__WIDTH) / 4);
  --Assets: calc(var(--quick_finance_details__WIDTH) / 4);
  --Liabilites: calc(var(--quick_finance_details__WIDTH) / 4);
  --Credit_Score: calc(var(--quick_finance_details__WIDTH) / 4.5);
}

.quick_finance_details {
  width: var(--quick_finance_details__WIDTH);
  display: flex;
  justify-content: space-between;
  height: calc(var(--quick_finance_detail) * 1%);
  position: relative;
  left: -1rem;
  gap: 1rem;
  /* ;background: rgb(225, 21, 21); */
  /* ;margin:0 0 10px 0; */
}

.Networth {
  border-radius: 8px;
  width: calc(var(--Networth) * 1%);
  height: 7.4vh;
  /* margin-left: 1.87%; */
  /* background: rgba(217, 217, 217, 0.121); */
  background: transparent;
  /* display: inline-block; */
  position: relative;
  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Assets {
  border-radius: 8px;
  width: calc(var(--Assets) * 1%);
  height: 7.4vh;
  background: rgba(217, 217, 217, 0.121);
  /* margin-left: 2.18%; */
  position: relative;
  display: inline-block;
  background: transparent;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Liabilites {
  border-radius: 8px;
  width: calc(var(--Liabilites) * 1%);
  height: 7.4vh;
  background: rgba(217, 217, 217, 0.121);
  /* margin-left: 2.18%; */
  position: relative;
  display: inline-block;
  background: transparent;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Credit-Score {
  width: calc(var(--Credit_Score) * 1%);
  border-radius: 8px;
  height: 7.4vh;
  background: rgba(217, 217, 217, 0.121);
  /* margin-left: 1.87%;   */
  position: relative;
  display: inline-block;
  background: transparent;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Networth:hover,
.Assets:hover,
.Liabilites:hover,
.Credit-Score:hover {
  border: 3px solid #33afeb;
  transform: scale(1.02);
}

.networth,
.Asset,
.Liabilite {
  width: 7.47vw;
  height: 2vh;
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(206, 206, 206, 0.8);
  margin-left: 5.66%;
  margin-top: 2%;
}

.networth-amount,
.Asset-amount,
.Liabilite-amount {
  font-family: "Orbitron";
  font-size: 1.625rem;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(206, 206, 206, 1);
  margin-left: 5.66%;
  margin-top: 2%;
  width: 8.59vw;
  height: 3.5vh;
}

.creditScore {
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(0, 215, 251, 1);
  margin-left: 5.66%;
  margin-top: 2%;
  width: 8vw;
  height: 1.94;
}

.credit-score-point {
  width: 8vw;
  height: 3.4vh;
  margin-left: 5.66%;
  margin-top: 2%;
  font-family: Orbitron;
  font-size: 26px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 215, 251, 1);
}
/* ================================ */
:root {
  --Finance_row_WIDTH: 100;
  --Division: calc(var(--Finance_row_WIDTH) / 2.6);
}
/* =================================== */
.Finance_row {
  width: calc(var(--Finance_row_WIDTH) * 1%);
  height: calc(var(--Finance_row) * 1%);
  display: flex;
  justify-content: space-between;

  /* height: calc((100 - var(--quick_finance_detail)) * 1%); */
  /* background: black; */
}

.Finance-right-first {
  /* box-sizing: border-box; */
  /* margin-top: 2.3%; */
  /* width: 40%; */
  width: calc(var(--Division) * 1%);
  /* height:60%; */
  height: calc(var(--Finance-right-first) * 1%);
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* background: rgb(0, 255, 34); */
  /* height: 100%; */
  /* margin-left: 2.18%; */
  /* display: inline-block; */
}

.Income {
  /* height: 26.20vh; */
  width: 100%;
  height: 35%;
  /* background: rgba(22, 23, 59, 1); */
  box-shadow: 0px 4px 8px 0px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 15px;
  height: calc(var(--Income) * 1%);
  /* background: red; */
  position: relative;
  background: transparent;
  /* z-index: 1; */

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}
:root {
  --Gradient_Border: -2px;
}

.income-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Orbitron";
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  /* margin-top: 2.12%; */
  /* margin-left: 3.05%; */
  width: 90%;
}

.income-details {
  font-family: "Poppins";
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
  background: rgba(22, 23, 59, 1);
  width: 35%;
  height: 2.96vh;
  border: 0.5px solid rgba(0, 215, 251, 1);
  box-shadow: 0px 2px 1px 0px rgba(64, 72, 82, 0.05);
  border-radius: 5px;
  letter-spacing: 1px;
  color: rgba(0, 215, 251, 1);
  /* margin-top: 1.72%; */
  /* margin-left: 53.05%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.income-info {
  font-family: "Orbitron";
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(115, 123, 139, 1);
  /* margin-top: .512%;
    margin-left: 3.05%; */
}
.income-comparing {
  font-size: 0.625rem;
}
.Income-graph {
  width: 60%;
  height: 9rem;
  /* height: 80%; */
  /* border: 1px solid black; */
  /* z-index: 50; */
  /* position: absolute; */
  /* height: 10px; */
  /* width: 23.45vw;
    height: 17.48vh; */
  /* padding-bottom: 7%; */
}
.Budgeting {
  width: 100%;
  /* height: 42.59vh; */
  /* background: rgba(22, 23, 59, 1); */
  box-shadow: 0px 4px 8px 0px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  /* margin-top: 4.62%; */
  padding: 14px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: calc((var(--Budgeting) - 3) * 1%);
  position: relative;
  background: transparent;
  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Budgeting-Header {
  font-family: "Orbitron";
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  margin-top: 2.12%;
  /* margin-left: 3.05%; */
}
.inner_Budgeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Budgeting-bar {
  width: 23vw;
  height: 0.925vh;
  background: rgba(45, 46, 76, 1);
  border-radius: 6px;
  /* margin-left: 3.05%; */
  margin-top: 5%;
}

.Budgeting-bar-progress {
  width: 9.2vw;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.budgeting-footer {
  font-family: "Orbitron";
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(184, 184, 184, 1);
  margin-top: 3.12%;
  /* margin-left: 3.05%; */
}

.Budget_Item {
  width: 90%;
  height: 60px;
  border-radius: 4px;
  /* margin-left: 3.05%; */
  margin-top: 4.02%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(var(--Gradient_Border_Finance_left) + 3px);
  /* background: linear-gradient(18deg, rgb(96, 255, 52), rgb(255, 0, 247)); */

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #fb4553 0%, #fa00ff 100%) 1;
}

.inner_Budget_Item {
  position: relative;
  /* background: black; */
  background: transparent;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Budget_Item-img {
  width: 50px;
  /* height: ; */
  /* margin-top: 3%;
  margin-left: 5%; */
}

.Budget_Item-description {
  color: rgba(255, 255, 255, 1);
  width: 60%;
  /* height: 4vh; */
  /* margin-left: 1%; */
  /* margin-top: 2%; */
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 200;
  letter-spacing: 2px;
  text-align: left;
}

.Budget_Item-date {
  color: rgba(255, 255, 255, 1);
  font-family: "Orbitron";
  font-size: 0.7rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
}

.Budget_Item-cost {
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  /* text-align: left; */
  color: white;
  /* margin-top: 3.2%; */
  /* margin-left: 21%; */
}

.Finance-Right-second {
  /* box-sizing: border-box; */
  /* margin-top: 2.3%;
    margin-left: 1.875%; */
  /* display: inline-block; */
  /* width: 24.79vw;  */
  width: calc(var(--Division) * 1%);

  /* background: red; */
  height: calc(var(--Finance_Right_second) * 1%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Expenses {
  width: 100%;
  /* height: 500px; */
  height: calc(var(--Expenses) * 1%);
  background: rgba(22, 23, 59, 1);
  box-shadow: 0px 4px 8px 0px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  position: relative;
  background: transparent;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.DoughnutChart {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 1%;
    margin-left: 1.5%; */
  /* width: 23.45vw;
    height: 28.48vh; */
}

.Expenses-header {
  font-family: "Orbitron";
  font-size: 1.5em;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  margin-top: 2.12%;
  margin-left: 3.05%;
}

.spending-Limits {
  width: 100%;
  height: 12rem;
  /* height: calc(var(--spending_Limits) * 1%); */
  background: rgba(22, 23, 59, 1);
  box-shadow: 0px 4px 8px 0px rgba(12, 14, 35, 0.7);
  border-radius: 8px;
  position: relative;
  background: transparent;
  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;

  /* margin-top: 3.33%; */
}

.spending-Limits-header {
  font-family: "Orbitron";
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #cecece;
  margin-top: 2.12%;
  margin-left: 3.05%;
}

.transaction-Limit {
  color: rgba(184, 184, 184, 0.8);
  font-family: "Orbitron";
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 1.12vh;
  margin-left: 0.75vw;
}

.spending-limits-bar {
  width: 22vw;
  height: 0.925vh;
  background: rgba(45, 46, 76, 1);
  border-radius: 6px;
  margin-left: 4%;
  margin-top: 4%;
  margin-bottom: 4%;
  width: 22vw;
  height: 0.925vh;
  background: rgba(45, 46, 76, 1);
  border-radius: 6px;
  margin-left: 4%;
  margin-top: 3%;
  margin-bottom: 4%;
}

.spending-limits-bar-progress {
  width: 7.3vw;
  height: 0.925vh;
  background: rgba(54, 196, 209, 1);
  border-radius: 6px;
}

.spending-Limits-footer {
  font-family: "Orbitron";
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(184, 184, 184, 0.8);
  margin-top: 0.52vh;
  margin-left: 0.75vw;
}

.Savings {
  height: 16rem;
  /* height: calc(var(--Savings) * 1%); */
  width: 100%;
  margin-bottom: -1.5rem;
  background: rgba(22, 23, 59, 1);
  box-shadow: 0px 4px 8px 0px rgba(10, 10, 31, 0.8);
  border-radius: 8px;
  position: relative;
  background: transparent;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;

  /* margin-top: 3.33%;   */
}

.savings-Header {
  display: flex;
  font-family: "Orbitron";
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  margin-top: 2.12%;
  /* padding-top: 10px; */
  margin-left: 3.05%;
}

.saving-Details {
  font-family: "Poppins";
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
  background: rgba(22, 23, 59, 1);
  width: 4.3vw;
  height: 2.96vh;
  border: 0.5px solid rgba(0, 215, 251, 1);
  box-shadow: 0px 2px 1px 0px rgba(64, 72, 82, 0.05);
  border-radius: 5px;
  color: rgba(0, 215, 251, 1);
  margin-top: 1.72%;
  margin-left: 53.05%;
  padding-left: 4px;
}

.savings-info {
  font-family: "Orbitron";
  font-size: 0.8125rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(115, 123, 139, 1);
  margin-top: 0.512%;
  margin-left: 3.05%;
}

.Finance-Right-third {
  /* box-sizing: border-box;
    display: inline-block; */
  /* margin-top: 2.3%;
    margin-left: 1.875%; */
}

.Goals {
  width: 12.91vw;
  height: 2.5vh;
  font-family: "Orbitron";
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
}

.goals-sign {
  margin-left: 50.61%;
  background: none;
  border: none;
}

.Holiday-Trip {
  width: 12.91vw;
  height: 20.35vh;
  margin-top: 4.94%;
  border-radius: 8px;
  background: linear-gradient(0deg, #16173b, #16173b);
  box-shadow: 0px 4px 8px 0px rgba(12, 14, 35, 0.7);
  /* border-top: 2px solid rgba(0, 215, 251, 1); */
  position: relative;
  background: transparent;
  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.New-House {
  width: 12.91vw;
  height: 20.35vh;
  margin-top: 10.33%;
  border-radius: 8px;
  background: linear-gradient(0deg, #16173b, #16173b);
  box-shadow: 0px 4px 8px 0px rgba(12, 14, 35, 0.7);
  /* border-top: 2px solid rgba(0, 215, 251, 1); */
  position: relative;
  background: transparent;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.Holiday-trip-sign,
.New-House-sign {
  background: none;
  border: none;
  margin-top: 1.12vh;
  margin-left: 0.75vw;
}

.Holiday-header,
.New-house-header {
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  margin-top: 1.12vh;
  margin-left: 0.75vw;
}

.New-house-Budget,
.holiday-Budget {
  font-family: "Orbitron";
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(237, 237, 237, 1);
  margin-top: 6.12vh;
  margin-left: 0.75vw;
}

.New-house-Budget-amount,
.holiday-Budget-amount {
  font-family: "Orbitron";
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(0, 215, 251, 1);
  margin-top: 0.12vh;
  margin-left: 0.75vw;
}

.News-title {
  background: transparent;
  width: 12.91vw;
  height: 12.18vh;
  margin-top: 10.05%;
  box-shadow: 0px 4px 8px 0px rgba(12, 14, 35, 0.7);
  border-radius: 8px;
  margin-bottom: 62%;
  display: flex;
  position: relative;

  border: 2px solid transparent;
  border-image: linear-gradient(130deg, #02d1ff 0%, #ff00b8 100%) 1;
}

.News-icon {
  /* display: inline-block; */
  border-radius: 2px;
  height: 7.96vh;
  width: 4.47vw;
  margin-top: 3.29%;
  margin-left: 3.725%;
  border: none;
}

.News-icon-Header {
  font-family: "Orbitron";
  /* display: inline-block; */
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  color: rgba(206, 206, 206, 1);
  margin-left: 43.75%;
  width: 5.78vw;
  box-sizing: border-box;
  margin: 11px;
}

.News-details {
  font-family: "Orbitron";
  font-size: 0.625rem;
  font-weight: 400;
  letter-spacing: 0.08em;
  color: #cecece;
}

.Income:hover,
.Budgeting:hover,
.Expenses:hover,
.spending-Limits:hover,
.Savings:hover,
.Holiday-Trip:hover,
.New-House:hover,
.News-title:hover {
  /* border: 2px solid #33afeb; */
}

/*  */

.Budgeting::before,
.Expenses::before,
.spending-Limits::before,
.Savings::before ,
.Holiday-Trip::before,
.New-House::before,
.News-title::before,
/* .Finance-left::before, */
.Income::before,
.Networth::before,
.Assets::before,
.Liabilites::before,
.Credit-Score::before {
  content: "";
  position: absolute;
  /* width: 100%;
    height: 100%; */
  top: var(--Gradient_Border_Finance_left);
  right: var(--Gradient_Border_Finance_left);
  bottom: calc(var(--Gradient_Border_Finance_left) - 0.5px);
  left: var(--Gradient_Border_Finance_left);
  border-radius: 10px;
  /* background: linear-gradient(18deg, rgb(96, 255, 52), rgb(255, 0, 247)); */
  z-index: -1;
}

@media screen and (max-width: 950px) {
  .Finance-left {
    width: 90%;
    height: fit-content;
  }
  .Finance_row {
    flex-direction: column;
  }
  .Finance-right-first {
    width: 100%;
  }
  .Finance-Right-second {
    width: 100%;
  }
  .Holiday-Trip {
    width: 100%;
  }
  .New-House {
    width: 100%;
  }
  .News-title {
    width: 100%;
  }
  .quick_finance_details {
    display: none;
  }
  .Investment-heading {
    margin-left: 5%;
  }
  .assets-Heading {
    margin-left: 5%;
  }
  .liabilites-Heading {
    margin-left: 5%;
  }
}
