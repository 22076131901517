.general-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
     
}

.left-general-settings {
    width: 50%;
     display: flex;
    flex-direction: column;
    /* left: 7rem; */
    justify-content: center;
    /* align-items: center;  */
    position: relative;
}


.right-general-settings {
    width: 50%;
}

.content-input{
    font-family: 'Oxanium';
    color: #EAEAEA;
    position: relative;
    /* margin-top: 20px; */
    font-size: 18px;
    left: 5rem;
}

.textbox-b {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    background-color: #d3dbf7;
    border-radius: 10px;
    font-family: 'Oxanium';
    color: #EAEAEA;
}

.textbox-g {
    width: 50%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: borders-box;
    border: none;
    background-color: #4C505C;
    border-radius: 10px;
    font-family: 'Oxanium';
    color: #141721;
}
.textbox-b::placeholder{
    color: #B8B8B8;
    font-size: 16px;
}.textbox-g::placeholder{
    color: #141721;
    font-size: 16px;
}

.country-city{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
}

.country-city .textbox-b,.country-city .textbox-g{
    width: 10rem;
}

.s-line3 {
    width: 50%;
}
.gender-title{
    font-family: 'Oxanium';
    color: #EAEAEA;
    margin-top: 20px;
    font-size: 18px;
    margin-left: 50px;
}
.gender{
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    margin-left: 10px;
    margin-top: 20px;

}

.gender-btn-item{
    background-color: inherit;
    border: 1px solid #EAEAEA;
    border-radius: 10px;
    padding: 20px;
    color: #EAEAEA;
    font-family: 'Oxanium';
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gender-head{
    font-size: large;
}
.gender-p{
    font-size: 12px;
}

