.login {

    background-color: #1A1B41;
    display: flex;
    color: #ffffff;
    font-size: 48px;
    text-align: center;
    overflow: hidden;
    height: 100vh;


}

.left {
    width: 70%;
    z-index: 1;
    background-image: url('../assets/images/background shapes.png');
    background-repeat: no-repeat;
    background-size: cover;
}

/* .bottom {
    width: 70%;
    z-index:0;
    background-image: url('../assets/images/Rectangle 121.png');
    background-repeat: no-repeat;
    background-size: cover;
    

} */

.left img {
    z-index: 2;
    height: 770px;
    width: 1154px;
    margin-top: 70px;
}

/* .polygon{
    height: 100vh;
    width: 50vw;
    background-repeat: no-repeat;
  background-size: cover;
} */

.right {
    width: 30%;
    border-right: 6px solid #33AFEB;
    border-left: 6px solid #33AFEB;
    border-top: 6px solid #33AFEB;
    border-bottom: 6px solid #33AFEB;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
    /* border-radius: 10px; */
}

.right:hover{
    border: 5px solid #33AFEB;
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
    
}

.blur {
    height: 98vh;
    position: absolute;
    z-index: 30;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-image: #1A1B41;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* border: 3px solid #C03848; */
    
}


.sign-in {
    border-radius: 10px;
    width: 100%;
    /* box-shadow: 5px 10px 50px 0px rgb(160, 69, 87) inset; */
    height: 100vh;
    z-index: 10;
    font-family: 'montserrat-sb';


}
/* border: 10px solid;

border-image-source: linear-gradient(180deg, rgba(0, 215, 251, 0.5) 0%, rgba(68, 41, 104, 0.5) 100%); */


.sign-up-title {
    z-index: 30;
    color: white;
    font-size: 24px;
    padding-top: 35%;
    padding-bottom: 20px;

}

.username,
.password {
    margin-top: 30px;
}

.username input,.password input {
    width: 370px;
    height: 50px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-family: 'Montserrat-1';
    font-size: 18px;
    background-color: #3D3E5D;
    border: 3px solid #00D7FB;
    border-radius: 10px;
}

.username input:active,
.password input:active {
    border: 3px solid #007589;
    border-radius: 10px;
}

.username input::placeholder,
.password input::placeholder {
    color: #EDEDED;
    background-color: #3D3E5D;
    font-size: 16px;
    font-family: 'montserrat-l';
}

.stay {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: 'montserrat-l';
    border-radius: 6px;
    
}

.stay input[type='checkbox'] {
    background-color: #3d3d3d;
    height: 25px;
    width: 25px;
    border-radius: 6px;

    
}

.stay input[type='checkbox']:hover {
    accent-color: #3d3d3d;
}

.stay input[type='checkbox']:checked {
    accent-color: #3D3E5D;
}

.checkmark {
    margin-left: 10px;
}

.stay a {
    color: #EDEDED;
    text-decoration: none;
    margin-left: 50px;
    font-size: 11px;
    font-family: 'montserrat-l';
}

.sign_in_button button {
    margin-top: 10%;
    height: 70px;
    width: 140px;
    font-size: large;
    border: none;
    border-radius: 3px;
    background-image: linear-gradient(#00F8FA, #009EB9);
}
.sign_in_button :hover {
    transform: scale(1.05);
   cursor: pointer;
   box-shadow: 0 0 30px rgba(74, 172, 228, 0.5);

}


.sign_up_link {
    color: #EDEDED;
    font-size: 14px;
    font-family: 'montserrat-l';
    margin-top: 30px;
}

.sign_up_link:hover{
    transform: scale(1.02);
}

.sign_in_button h5 {
    padding: 0;
    margin-top: 9px;
    font-size: 20px;
    font-weight: 700;
    color: black;
    
}

.sign_up_link a {
    color: #EDEDED;
    text-decoration: none;
}

.ToastLogin{
    height: 55px;
    width: 300px;
    font-size: 18px;
    padding-bottom: 55px;
  }