.navbar{
    overflow: hidden;
    background-color: #141721;
    /* background-color: white; */
    position: fixed;
    /* bottom: 50%; */
    top:50%;
    left: 2%;
    transform: translate(-50%,-50%);
    height: 90%;
    width: 50px;
    border-radius: 50px;    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.navbar-list{
    height: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    /* background: red; */
}

.navbar img{
    height: 25px;
}


.navbar img:hover{
    transition: 0.3s;
    /* border-bottom: solid 3px rgba(51, 175, 235, 1); */
    /* transition: border-bottom 0.3s linear; */
    transform: scale(1.2);
    cursor: pointer;
}

.drop{
    background-color: red;
    width: 500px;
    height: 1000px;
    z-index: 4;
    position: relative;
    top: 0;
    right: 0;
}
.navbar .navbar-list li NavLink{
    transition: 0.5s ease;
} 


/* .navbar .navbar-list  li  .active img{
    border-bottom: solid 5px rgba(51, 175, 235, 1);
    border-radius: 2px; 

} */














