*{
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  /* overflow: hidden;s */
}

:root {
  --lightgray: #D9D9D9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  /* --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%); */
  --appColor:  #3c3f45;
  --planCard: linear-gradient(to right, #8900B9, #2905A157);
  
}

@font-face {
  font-family: 'Oxanium';
  src: url('./assets/fonts/Oxanium-Regular.ttf');
}

@font-face {
  font-family: 'Orbitron';
  src: url('./assets/fonts/Orbitron-Medium.ttf');
}

@font-face {
  font-family: 'IBM';
  src: url('./assets/fonts/IBMPlexSans-Thin.ttf');
}
@font-face {
  font-family: 'IBM-m';
  src: url('./assets/fonts/Orbitron-Medium.ttf');
}

@font-face {
  font-family: 'montserrat-sb';
  src: url('./assets/fonts/Montserrat-SemiBold.otf');
}

@font-face {
  font-family: 'montserrat-l';
  src: url('./assets/fonts/Montserrat-Light.otf');
}
@font-face {
  font-family: 'montserrat-m' ;
  src: url('./assets/fonts/Montserrat-Regular.otf');
}

.content-title {
  font-family: 'Oxanium';
  color: #EAEAEA;
  margin-top: 20px;
  font-size: 20px;
  margin-left: 50px;
}

.content-subtitle {
  font-family: 'Oxanium';
  color: #EAEAEA;
  margin-top: 20px;
  font-size: 18px;
  margin-left: 50px;
}


@media screen and (max-width : 768px){

  .content-title{
    width: 90vw;
  }
}