.WarningModal{
    background-color: #1A1B41;
    position: absolute;
    width: 648px;
    height: 130px;
    border: 2px solid black;
    border-radius: 10px;
    display: flex;
    left: 270px;
    top: 401px;
    z-index: 3;
}

.WarningInfo{
    font-family: "Oxanium";
font-size: 20px;
font-weight: 400;
line-height: 35px;
letter-spacing: 0em;
text-align: center;
color: rgba(142, 204, 211, 1);
margin-left: 18px;
margin-right: 20px;
margin-top: 7px;
}

.CloseDialoge4{
    background: none;
    border: 2px solid black;
    color: white;
    height: 42px;
    width: 170px;
    text-align: center;
    border-radius: 8px;
    margin-top: 3px;
    margin-left: 208px;
    font-family: 'Orbitron';
    font-size: 15px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;

}

.CloseDialoge4 :hover{
    transform: scale(1.05);
    cursor: pointer;
    background: linear-gradient(180deg, #00FFFF 0%, #009EB9 100%);
    box-shadow: 9.22567e-16px 15.0667px 30.1333px rgba(0, 43, 50, 0.422), inset -3.93629e-16px -6.42844px 6.42844px #0090A8, inset 3.93629e-16px 6.42844px 6.42844px #00FFFF;
}