/*

 General Settings
 
 */
.general-settings {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.left-general-settings {
  width: 50%;
  /* background: rgb(81, 255, 0); */
}

.right-general-settings {
  width: 50%;
}

.textbox-b {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #141721;
  border-radius: 10px;
  font-family: "Oxanium";
  color: #eaeaea;
}

.textbox-g {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  background-color: #4c505c;
  border-radius: 10px;
  font-family: "Oxanium";
  color: #141721;
}

.textbox-b::placeholder {
  color: #b8b8b8;
  font-size: 16px;
}

.textbox-g::placeholder {
  color: #141721;
  font-size: 16px;
}

.country-city {
  width: 65%;
  display: flex;
  justify-content: space-evenly;
}

.country-city .textbox-b,
.country-city .textbox-g {
  width: 100%;
}

.s-line3 {
  width: 50%;
}

.gender {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  margin-left: 10px;
  margin-top: 20px;
}

.gender-btn-item {
  background-color: inherit;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 20px;
  color: #eaeaea;
  font-family: "Oxanium";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gender-head {
  font-size: large;
}

.gender-p {
  font-size: 12px;
}

/*

Payment Info
 
 */

.plan {
  display: flex;
  flex-wrap: wrap;
}

.plan1 {
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 200px;
    border-image:linear-gradient(200deg, #33AFEB 0%, #FF00C7 100%) 1;
  /* width:50% ; */
  flex-basis: calc(30% - 10px);
  height: 8rem;
  margin: 20px 50px 20px 50px;
  padding: 20px;
}
.plan1:hover {
  transform: scale(1.02);
  cursor: pointer;
  border: 3px solid #33afeb;
}
.plan2 {
  border-radius: 14.799px;
  border: 1.85px solid #3c96bb;
  background: linear-gradient(180deg, #33afeb 0%, rgba(51, 175, 235, 0) 100%);
/* border-radius: 10px; */
  /* background-color: #33afeb; */
  flex-basis: calc(30% - 10px);
  /* width: 300px; */
  margin: 20px 50px 20px 50px;
  padding: 20px;
}

.plan2:hover {
  transform: scale(1.02);
  cursor: pointer;
  border: 3px solid #33afeb;
}

.basic {
  display: flex;
  justify-content: space-between;
}

.plan-title {
  font-family: "Oxanium";
  font-size: 22px;
  color: #ffffff;
}

.plan-bold {
  font-size: 22px;
  color: #ffffff;
}

.plan-amt {
  font-family: "Oxanium";
  color: #b8b8b8;
  font-size: 16px;
}

.plan-description {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Oxanium";
  color: #b8b8b8;
  font-size: 14px;
}
.plan_button{
    height: 2.2rem;
    width: 13rem;
}

.plan2 .plan-title,
.plan2 .plan-bold,
.plan2 .plan-amt,
.plan2 .plan-description {
  color: #16173b;
}

.renew {
  margin-top: 30px;
  font-family: "Oxanium";
  color: #b8b8b8;
  font-size: 16px;
}

.renew-des {
  margin-top: 20px;
  margin-left: 50px;
}

.payment-method-list {
  display: flex;
  list-style-type: none;
  margin-left: 50px;
  flex-wrap: wrap;
  margin-top: 30px;
}


.payment-method-item {
  width: 100px;
  margin-right: 30px;
  border: 2px solid transparent; 
  border-image:linear-gradient(200deg, #02d1ff 0%, #ff00b8 100%)1;
  /* border-radius: 3rem; */
  background-color: #1c1f2c;
  font-family: "Oxanium";
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding: 20px 30px 20px 30px;
}

.payment-method-item:hover {
  transform: scale(1.02);
  cursor: pointer;
  border: 3px solid #33afeb;
}

.card1,
.card2 {
  width: 250px;
  background-color: #28263d;
  border-radius: 25px;
  margin-left: 50px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 20px;
}
.card1{
    border-radius: 27.748px;
    background: linear-gradient(135deg, #E8126C 0%, rgba(0, 125, 152, 0.76) 100%);
}
.card1:hover,
.card2:hover {
  transform: scale(1.02);
  cursor: pointer;
  border: 3px solid #33afeb;
}

.payment-card {
  display: flex;
  flex-wrap: wrap;
}

.card-type {
  font-family: "Oxanium";
  color: #b8b8b8;
  font-size: 18px;
}

.card-img-num {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.card-img {
  width: 50px;
  height: 30px;
  background-color: #ffffff;
  margin-right: 10px;
}

.card-num {
  font-family: "Oxanium";
  color: #b8b8b8;
  font-size: 15px;
}

/* Create Account */
.create-account {
  display: flex;
  width: 100%;
  background: transparent;
}

.create-account-left {
  width: 50%;
}

.create-account-right {
  width: 50%;
}

.created-accounts {
  margin-top: 30px;
  width: 100%;
}

.invite-friends {
  display: flex;
  justify-content: center;
}

.friends-bg:hover {
  transform: scale(1.01);
  cursor: pointer;
  border: 3px solid #33afeb;
}

.table-heading {
  font-family: "Oxanium";
  color: #ededed;
  font-size: 20px;
  font-weight: 500;
}

.table-user {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Oxanium";
  color: #ededed;
  font-size: 18px;
}
.table-user img {
  margin-right: 20px;
  height: 50px;
}
.table-session-info {
  text-align: center;
  font-family: "Oxanium";
  color: #b8b8b8;
  font-size: 18px;
}

.table-status {
  text-align: center;
  font-family: "Oxanium";
  color: #42bd2e;
  font-size: 18px;
}

.created-accounts td {
  margin-left: 50px;
  margin-top: 20px;
}

.friends-bg {
  margin-top: 50px;
  height: 120px;
  width: 80%;
  background: linear-gradient(#313a57, #231e38);
  border-radius: 20px;
  padding: 30px;
  background-color: transparent;
  border: 2px solid transparent;
  /* border-radius: 20rem; */
  border-image: linear-gradient(200deg, #33AFEB 0%, #FF00C7 100%) 1;
}

.invite-your-friends {
  font-family: "Orbitron";
  color: #eaeaea;
  font-size: 30px;
}
.friends-description {
  font-family: "Oxanium";
  color: #b8b8b8;
  margin-top: 10px;
  font-size: 16px;
}
.input-email-id {
  margin-left: 50px;
}

.line-or {
  margin-top: 20px;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oxanium";
  color: #b8b8b8;
}
.line {
  width: 100px;
  height: 2px;
  background-color: #b8b8b8;
  margin-right: 30px;
  margin-left: 30px;
}

/* <----------------general Settings-------------> */
@media screen and (max-width : 768px){

  .left-general-settings{
    width: 100%;
    margin-left: -2rem;
  }
  .right-general-settings{
    width: 100%;
    margin-left: -2rem;
  }
  .gender{
    margin-left: 3rem;
    flex-wrap: wrap;
  }

  .country-city{
    flex-wrap: wrap;
  }

  .country-title{
    width: inherit;
  }
}



/* <-------------------------Payment-info------------------------> */

@media screen and (max-width : 768px) {
  /* .plans{
      flex-direction: column;
  }
  .plan:nth-child(2){
      transform: none;
  } */

  .general-settings {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  .payment-card{
    margin-left: -2rem;
  }
  .plan1{
    flex-basis: calc(70% - 10px);
    width: 60%;
  }
  .plan2{
    flex-basis: calc(70% - 10px);
    width: 60%;
  }

  .plan_button{
    width: 90%;
  }

  
  
}


@media screen and (max-width : 950px){

  .plan1{
    flex-basis: calc(70% - 10px);
  }
  .plan2{
    flex-basis: calc(70% - 10px);
  }

  .create-account{
    flex-direction: column;
  }
  .create-account-left{
    width: 100%;
  }
  .create-account-right{
    width: 100%;
  }
}