.LandingImage{
    width: 100vw;
    height: 100vh;
    position: relative;
}

.landingContent{
    position: absolute;
    top: 370px;
    left: 508px;
}

.AVA{
    color: #36C4D1;
    font-family: "Orbitron";
    font-size: 56px;
    font-weight: 300;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 28%;

}

.avaParagraph{
    display: flex;
    width: 60%;
    font-family: "Oxanium";
    font-size: 28px;
    font-weight: 300;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
    color:rgba(142, 204, 211, 1);
    margin-left: 8%;
    
}

.EnterExperienceButton{
    border: 2px solid black;
    background-color: #36C4D1;
    margin-left: 22%;
    margin-bottom: 1%;
    height: 75px;
    width: 17vw;
    border-radius: 10px;
    font-family: "Orbitron";
    font-style: Medium;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: rgba(9, 31, 64, 1);
}

.EnterExperienceButton:hover{
    transform: scale(1.05);
    cursor: pointer;
    box-shadow:0px 0px 30px #36C4D1;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

.global-loading.fade-out {
    opacity: 0;
    transition: opacity 3s ease;
}

/* @keyframes glow {
    from {
        text-shadow: 0 0 10px #36C4D1;
    }
    to {
        text-shadow: 0 0 20px #36C4D1;
    }
}

.AVA, .avaParagraph {
    animation: glow 1.5s ease-in-out infinite;
} */

/* @keyframes glow {
    from {
        text-shadow: 0 0 10px #F7E7CE;
    }
    to {
        text-shadow: 0 0 20px #F7E7CE;
    }
}

.AVA, .avaParagraph {
    animation: glow 1s ease-in-out infinite;
} */


@keyframes glow {
    from {
        text-shadow: 0 0 10px #A475CB;
    }
    to {
        text-shadow: 0 0 20px #A475CB;
    }
}

.AVA, .avaParagraph {
    animation: glow 2s ease-in-out infinite;
}