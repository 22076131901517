.billing{
    height: 100vh;
    position: relative;
    overflow: hidden;
    background:linear-gradient(#191A40,#090B22);
}

.billing-background{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.billing-content{
    background: rgba(255, 255, 255, 0.171);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    /* margin: -70px 50px 40px 50px; */
    height: 90%;
    width: 90%;
    overflow-y: scroll;
    overflow-x: hidden;

}
 /* ===== Scrollbar CSS ===== */
  /* Firefox */
  .billing-content{
    scrollbar-width: auto;
    scrollbar-color: #16173B;
  }

  /* Chrome, Edge, and Safari */
  .billing-content::-webkit-scrollbar {
    width: 8px;
  }

  .billing-content::-webkit-scrollbar-track {
    background: inherit;
  }

  .billing-content::-webkit-scrollbar-thumb {
    background-color: #16173B;
    border-radius: 10px;
    border: none;
  }

/* billing Navbar */
.billingNav{
  display: flex;
    justify-content: center;
    align-items: center;
    
}
.billing-navbar{
   
  border-image: linear-gradient(200deg, #02d1ff 0%, #ff00b8 100%) 1;
    background-color: transparent;
    border: 2px solid transparent;
    /* border-image: linear-gradient*( 0%, #FF00E5, #00D1FF); */
    /* border-image: linear-gradient(to bottom, #FF00E5, #00D1FF) 1; */
    display: flex;
    justify-content: left;
    gap: 2rem;
    align-items: center;
    width: 95%;
    height: 60px;
    /* margin: 20px 30px 10px 40px; */
    border-radius: 4px;

}



.billing-item {
  font-size: 1.5rem;
  font-family: 'Oxanium';
  background-color: inherit;
  border: none;
  background-color: inherit;
  color: #FFFFFF;
  padding: 15px 45px;
  /* padding: 0.8rem 3rem 2rem 2rem; */
  border-radius: 10px;
  background: -webkit-linear-gradient(126deg, #02d1ff 0%, #ff00b8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .billing-item:focus{
    background-color: #3C96BB;
    color: #16173B;
} */

.billingNav .billing-navbar  li{
  transition: 0.5s ease;
  list-style: none;
}

.billingNav .billing-navbar li .active{
  background-color: #141721;
  display: inline-table;
  color: #16173B;
  height: 2rem;
  border-radius: 1rem;
  border: 2px solid transparent;
  border-image: linear-gradient(200deg, #02d1ff 0%, #ff00b8 100%) 1;
} 